<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="95%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Capture A Loan Quote</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddLoanDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1" color="success">Pricing</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 2" step="2" color="success">Loan Details</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="3" color="success">Processing</v-stepper-step>
                </v-stepper-header>
            
                <v-stepper-items>
                    <!-- Basic Details -->
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="3">
                                        <!-- BASIC DETAILS -->
                                        <v-row style="background:#4490a030; margin-right: 1px;">
                                            <v-col cols="12" sm="12" >
                                                <h3>Basic Details</h3>
                                            </v-col>
                                            <v-col cols="12" sm="5">
                                                <CurrencyInput
                                                    v-model="pricing_details.principal_amount"
                                                    label="Principal Amount"
                                                    :options="currencyOptions"
                                                    hint="e.g. 3 500"
                                                />
                                            </v-col>
                                            <v-col cols="12" sm="7">
                                                <v-select
                                                    @change="loanTypeChanged"
                                                    v-model="pricing_details.loan_type"
                                                    :items="loan_types_list"
                                                    item-text="name"
                                                    item-value="loan_type_id"
                                                    label="Loan Type"
                                                    color="secondary"
                                                    hint="Loan Type"
                                                    persistent-hint
                                                    required
                                                ></v-select>
                                            </v-col>
                                            <!-- <v-col cols="12" sm="4">
                                                <v-select
                                                    v-model="pricing_details.loan_term_units"
                                                    :items="['HOURS', 'DAYS', 'WEEKS', 'MONTHS', 'YEARS']"
                                                    label="Loan Term Units"
                                                    color="secondary"
                                                    hint="Loan Term Units"
                                                    persistent-hint
                                                    disabled
                                                    required
                                                ></v-select>
                                            </v-col> -->
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" sm="9">
                                        <!-- RETURNS CONFIG -->
                                        <v-row style="background: #e9e9e9">
                                            <v-col cols="12" sm="12">
                                                <h3>Returns Config</h3>
                                            </v-col>
                                            <!-- Max Annual Interest Rate -->
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="pricing_details.interest_config.annual_interest_rate"
                                                    color="secondary"
                                                    label="Total Interest Rate Charged"
                                                    type="number"
                                                    prefix="%"
                                                    required
                                                    hint="Total Interest Rate Charged"
                                                    persistent-hint
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                v-if="isCentralUnderwriterAdminUser"
                                                cols="12"
                                                sm="3"
                                            >
                                                <v-text-field
                                                    v-model="pricing_details.interest_config.annual_interest_rate_monthly"
                                                    color="secondary"
                                                    label="Max Monthly Interest Rate"
                                                    type="number"
                                                    prefix="%"
                                                    required
                                                    hint="Max Monthly Interest Rate"
                                                    persistent-hint
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2">
                                                <v-text-field
                                                    v-model="pricing_details.loan_term"
                                                    color="secondary"
                                                    label="Loan Term"
                                                    type="number"
                                                    :suffix="pricing_details.loan_term_units"
                                                    hint="Loan Term"
                                                    persistent-hint
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                            <!-- <v-col cols="12" sm="1"></v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="pricing_details.interest_config.desired_monthly_target_return"
                                                    color="secondary"
                                                    label="Target Monthly Return %"
                                                    type="number"
                                                    prefix="%"
                                                    required
                                                    hint="Target Monthly Return %"
                                                    persistent-hint
                                                ></v-text-field>
                                            </v-col> -->
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <br>
                                <br>

                                <!-- <v-row>
                                    <v-col cols="12" sm="5"></v-col>
                                    <v-col cols="12" sm="7">
                                        <v-row style="background: #9fc5ce; margin-right: 1px;">
                                            <v-col cols="12" sm="12" >
                                                <h3>Premium Allocation Priority</h3>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="pricing_details.premium_allocation_priority.principal"
                                                    color="secondary"
                                                    label="Principal"
                                                    type="number"
                                                    hint="1 - 4"
                                                    persistent-hint
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="pricing_details.premium_allocation_priority.interest"
                                                    color="secondary"
                                                    label="Interest "
                                                    type="number"
                                                    hint="1 - 4"
                                                    persistent-hint
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="pricing_details.premium_allocation_priority.admin_fee_premium"
                                                    color="secondary"
                                                    label="Admin Fee Premium"
                                                    type="number"
                                                    hint="1 - 4"
                                                    persistent-hint
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="pricing_details.premium_allocation_priority.initiation_fee_premium"
                                                    color="secondary"
                                                    label="Initiation Fee Premium"
                                                    type="number"
                                                    hint="1 - 4"
                                                    persistent-hint
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <br>
                                <br> -->

                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <!-- FEES CONFIG -->
                                        <v-row style="background: #afd7c4">
                                            <v-col cols="12" sm="12">
                                                <h3>Fees Config</h3>
                                            </v-col>
                                            <!-- <v-col cols="12" sm="2">
                                                <v-checkbox
                                                    v-model="pricing_details.fees_config.initiation_fee_paid_at_inception"
                                                    color="secondary"
                                                    label="Init Fee Paid At Inception"
                                                    required
                                                ></v-checkbox>
                                            </v-col> -->

                                            <v-col
                                                v-if="isCentralUnderwriterAdminUser"
                                                cols="12"
                                                sm="2"
                                            >
                                                <v-text-field
                                                    v-model="pricing_details.fees_config.initiation_fee_principal_percentage"
                                                    color="secondary"
                                                    label="Init Fee Percentage"
                                                    type="number"
                                                    prefix="%"
                                                    required
                                                    hint="0% - 15%"
                                                    persistent-hint
                                                    disabled
                                                >
                                                    <v-icon
                                                        v-if="parseFloat(pricing_details.fees_config.initiation_fee_principal_percentage) < 15"
                                                        slot="append-outer"
                                                        color="red"
                                                        @click="updateInitFee('plus')"
                                                    >
                                                        mdi-plus
                                                    </v-icon>
                                                    <v-icon
                                                        v-if="parseFloat(pricing_details.fees_config.initiation_fee_principal_percentage) > 0"
                                                        slot="prepend"
                                                        color="green"
                                                        @click="updateInitFee('minus')"
                                                    >
                                                        mdi-minus
                                                    </v-icon>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="1"></v-col>
                                            <v-col cols="12" sm="1">
                                                <v-text-field
                                                    :value="initiationFeeAmount"
                                                    color="secondary"
                                                    label="Initiation Fee Amount"
                                                    type="number"
                                                    prefix="ZAR"
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="1"></v-col>
                                            <v-col cols="12" sm="2">
                                                <v-text-field
                                                    v-model="pricing_details.fees_config.monthly_admin_fee"
                                                    color="secondary"
                                                    label="Monthly Admin Charge"
                                                    type="number"
                                                    prefix="ZAR"
                                                    required
                                                    hint="R0 - R60"
                                                    persistent-hint
                                                    disabled
                                                >
                                                    <v-icon
                                                        v-if="(
                                                            isCentralUnderwriterAdminUser &&
                                                            parseFloat(pricing_details.fees_config.monthly_admin_fee) < 60
                                                        )"
                                                        slot="append-outer"
                                                        color="red"
                                                        @click="updateMonthlyAdminFee('plus')"
                                                    >
                                                        mdi-plus
                                                    </v-icon>
                                                    <v-icon
                                                        v-if="(
                                                            isCentralUnderwriterAdminUser &&
                                                            parseFloat(pricing_details.fees_config.monthly_admin_fee) > 0
                                                        )"
                                                        slot="prepend"
                                                        color="green"
                                                        @click="updateMonthlyAdminFee('minus')"
                                                    >
                                                        mdi-minus
                                                    </v-icon>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="1"></v-col>
                                            <v-col
                                                v-if="isCentralUnderwriterAdminUser"
                                                cols="12" sm="2"
                                            >
                                                <v-text-field
                                                    :value="monthlyAdminFeePercentage"
                                                    color="secondary"
                                                    label="Monthly Admin Fee Percentage"
                                                    type="number"
                                                    prefix="%"
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <br>
                                <br>

                                <v-row>
                                    <!-- <v-col cols="12" sm="12">
                                        <v-checkbox
                                            v-model="pricing_details.specialized_landing"
                                            color="secondary"
                                            label="Specialized Landing"
                                            required
                                        ></v-checkbox>
                                    </v-col> -->
                                    <v-col cols="12" sm="12">
                                        <v-card style="background: #9a9a0030">
                                            <v-card-subtitle><b>Amortization Schedule Config</b></v-card-subtitle>
                                            <v-card-text>
                                                <v-row
                                                    v-for="(scheduleConfig, i) in pricing_details.amortization_schedule_config"
                                                    :key="i"
                                                    style="padding-bottom:10px;"
                                                >
                                                    <AmortizationScheduleItem
                                                        :schedule_config="scheduleConfig"
                                                        :pricing_details="pricing_details"
                                                    />
                                                    <div style="background:white; height: 2px; width: 100%"></div>
                                                </v-row>
                                                <!-- Buttons for Adding/Removing Amortization Schedule Config Details -->
                                                <v-row v-if="(pricing_details.specialized_landing === true)">
                                                    <v-col sm="10"></v-col>
                                                    <v-col sm="2">
                                                        <v-tooltip bottom v-if="pricing_details.amortization_schedule_config.length > 1">
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn
                                                                    class="mx-2"
                                                                    color="secondary"
                                                                    small
                                                                    v-on="on"
                                                                    @click="removeAmortizationScheduleConfigItem()"
                                                                >
                                                                    <v-icon>remove</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Remove Schedule Config Item</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn
                                                                    color="secondary"
                                                                    small
                                                                    v-on="on"
                                                                    @click="addAmortizationScheduleConfigItem()"
                                                                >
                                                                    <v-icon>add</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Add Another Schedule Config Item</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    x-large
                                    :disabled="submitCalculationBtnDisabled()"
                                    @click="submitCalculation()"
                                    color="success"
                                >
                                    Calculate <v-icon x-large>mdi-calculator-variant-outline</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    large
                                    color="amber"
                                    :disabled="submitCalculationBtnDisabled()"
                                    @click="(e1 = 2)"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Loan Details -->
                    <v-stepper-content step="2">
                        <v-card>
                            <v-card-text>
                                <!-- REPAYMENT DETAILS -->
                                <v-row style="background: #ffc496;">
                                    <v-col cols="12" sm="12" >
                                        <h3>Repayment Details</h3>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-select
                                            v-model="loan_quote_details.repayment_config.payment_method"
                                            :items="payment_methods"
                                            label="Repayment Method"
                                            color="secondary"
                                            hint="Payment method"
                                            persistent-hint
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-select
                                            v-model="loan_quote_details.repayment_config.repayment_frequency"
                                            :items="['HOURLY', 'DAILY', 'WEEKLY', 'MONTHLY']"
                                            label="Repayment Frequency"
                                            color="secondary"
                                            hint="Repayment Frequency"
                                            persistent-hint
                                            disabled
                                            required
                                        ></v-select>
                                    </v-col>
                                    <!-- <v-col cols="12" sm="6">
                                        <v-text-field
                                            v-model="loan_quote_details.repayment_config.elected_repayment_time_point"
                                            color="secondary"
                                            label="Elected Repayment Time Point"
                                            type="number"
                                            hint="Hourly: 0-59,  Daily: 0-24,  Weekly: 1-7,  Monthly: 1-31"
                                            persistent-hint
                                            required
                                        ></v-text-field>
                                    </v-col> -->
                                </v-row>
                                <br>
                                <br>

                                <!-- <v-row>
                                    <v-col cols="12" sm="5">
                                        
                                    </v-col>
                                    <v-col cols="12" sm="7">
                                        <v-row style="background: #9fc5ce; margin-right: 1px;">
                                            <v-col cols="12" sm="12" >
                                                <h3>Premium Allocation Priority</h3>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="pricing_details.premium_allocation_priority.principal"
                                                    color="secondary"
                                                    label="Principal"
                                                    type="number"
                                                    hint="1 - 4"
                                                    persistent-hint
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="pricing_details.premium_allocation_priority.interest"
                                                    color="secondary"
                                                    label="Interest "
                                                    type="number"
                                                    hint="1 - 4"
                                                    persistent-hint
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="pricing_details.premium_allocation_priority.admin_fee_premium"
                                                    color="secondary"
                                                    label="Admin Fee Premium"
                                                    type="number"
                                                    hint="1 - 4"
                                                    persistent-hint
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="pricing_details.premium_allocation_priority.initiation_fee_premium"
                                                    color="secondary"
                                                    label="Initiation Fee Premium"
                                                    type="number"
                                                    hint="1 - 4"
                                                    persistent-hint
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <br>
                                <br> -->

                                <!-- BANK STATEMENT DETAILS -->
                                <!-- <v-row style="background:#1bd37130">
                                    <v-col cols="12" sm="12" >
                                        <h3>Bank Statement Details</h3>
                                    </v-col>

                                    <v-col cols="12" sm="3" style="border: solid 3px grey; border-radius:10px">
                                        <v-row>
                                            <v-col cols="12" sm="12" >
                                                <h4>Statement - Month 1</h4>
                                            </v-col>
                                            <v-col cols="12" sm="12">
                                                <v-menu
                                                    v-model="pricing_details.bank_statement_details.salary_1.date_on_statement_menu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="pricing_details.bank_statement_details.salary_1.date_on_statement"
                                                        color="secondary"
                                                        label="Salary Date on Statement"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        hint="e.g. 2022-02-12"
                                                        persistent-hint
                                                    ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        color="secondary"
                                                        v-model="pricing_details.bank_statement_details.salary_1.date_on_statement"
                                                        @input="pricing_details.bank_statement_details.salary_1.date_on_statement_menu = false"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>

                                            <v-col cols="12" sm="12">
                                                <CurrencyInput
                                                    v-model="pricing_details.bank_statement_details.salary_1.balance_1_day_after"
                                                    hint="e.g. 6 000"
                                                    label="Balance After Salary - End of Day 1"
                                                    :options="currencyOptions"
                                                />
                                            </v-col>

                                            <v-col cols="12" sm="12">
                                                <CurrencyInput
                                                    v-model="pricing_details.bank_statement_details.salary_1.balance_2_days_after"
                                                    hint="e.g. 6 000"
                                                    label="Balance After Salary - End of Day 2"
                                                    :options="currencyOptions"
                                                />
                                            </v-col>

                                            <v-col cols="12" sm="12">
                                                <CurrencyInput
                                                    v-model="pricing_details.bank_statement_details.salary_1.balance_3_days_after"
                                                    hint="e.g. 6 000"
                                                    label="Balance After Salary - End of Day 3"
                                                    :options="currencyOptions"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" sm="1"></v-col>

                                    <v-col cols="12" sm="3" style="border: solid 3px grey; border-radius:10px">
                                        <v-row>
                                            <v-col cols="12" sm="12" >
                                                <h4>Statement - Month 2</h4>
                                            </v-col>
                                            <v-col cols="12" sm="12">
                                                <v-menu
                                                    v-model="pricing_details.bank_statement_details.salary_2.date_on_statement_menu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="pricing_details.bank_statement_details.salary_2.date_on_statement"
                                                        color="secondary"
                                                        label="Salary Date on Statement"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        hint="e.g. 2022-02-12"
                                                        persistent-hint
                                                    ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        color="secondary"
                                                        v-model="pricing_details.bank_statement_details.salary_2.date_on_statement"
                                                        @input="pricing_details.bank_statement_details.salary_2.date_on_statement_menu = false"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>

                                            <v-col cols="12" sm="12">
                                                <CurrencyInput
                                                    v-model="pricing_details.bank_statement_details.salary_2.balance_1_day_after"
                                                    hint="e.g. 6 000"
                                                    label="Balance After Salary - End of Day 1"
                                                    :options="currencyOptions"
                                                />
                                            </v-col>

                                            <v-col cols="12" sm="12">
                                                <CurrencyInput
                                                    v-model="pricing_details.bank_statement_details.salary_2.balance_2_days_after"
                                                    hint="e.g. 6 000"
                                                    label="Balance After Salary - End of Day 2"
                                                    :options="currencyOptions"
                                                />
                                            </v-col>

                                            <v-col cols="12" sm="12">
                                                <CurrencyInput
                                                    v-model="pricing_details.bank_statement_details.salary_2.balance_3_days_after"
                                                    hint="e.g. 6 000"
                                                    label="Balance After Salary - End of Day 3"
                                                    :options="currencyOptions"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" sm="1"></v-col>

                                    <v-col cols="12" sm="3" style="border: solid 3px grey; border-radius:10px">
                                        <v-row>
                                            <v-col cols="12" sm="12" >
                                                <h4>Statement - Month 3</h4>
                                            </v-col>
                                            <v-col cols="12" sm="12">
                                                <v-menu
                                                    v-model="pricing_details.bank_statement_details.salary_3.date_on_statement_menu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="pricing_details.bank_statement_details.salary_3.date_on_statement"
                                                        color="secondary"
                                                        label="Salary Date on Statement"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        hint="e.g. 2022-02-12"
                                                        persistent-hint
                                                    ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        color="secondary"
                                                        v-model="pricing_details.bank_statement_details.salary_3.date_on_statement"
                                                        @input="pricing_details.bank_statement_details.salary_3.date_on_statement_menu = false"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>

                                            <v-col cols="12" sm="12">
                                                <CurrencyInput
                                                    v-model="pricing_details.bank_statement_details.salary_3.balance_1_day_after"
                                                    hint="e.g. 6 000"
                                                    label="Balance After Salary - End of Day 1"
                                                    :options="currencyOptions"
                                                />
                                            </v-col>

                                            <v-col cols="12" sm="12">
                                                <CurrencyInput
                                                    v-model="pricing_details.bank_statement_details.salary_3.balance_2_days_after"
                                                    hint="e.g. 6 000"
                                                    label="Balance After Salary - End of Day 2"
                                                    :options="currencyOptions"
                                                />
                                            </v-col>

                                            <v-col cols="12" sm="12">
                                                <CurrencyInput
                                                    v-model="pricing_details.bank_statement_details.salary_3.balance_3_days_after"
                                                    hint="e.g. 6 000"
                                                    label="Balance After Salary - End of Day 3"
                                                    :options="currencyOptions"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <br>
                                <br> -->


                                <!-- LOAN ENTITY -->
                                <v-row style="background:#4490a030" v-if="electedRepaymentTimePointValid">
                                    <v-col cols="12" sm="12" >
                                        <h3>Entity Details</h3>
                                    </v-col>

                                    <v-col cols="12" sm="3" offset-sm="2">
                                        <v-select
                                            v-model="loan_quote_details.loan_reason"
                                            :items="loan_reasons"
                                            item-text="reason"
                                            return-object
                                            label="Loan Reason"
                                            color="secondary"
                                            hint="Reason for taking the loan"
                                            persistent-hint
                                            required
                                        >
                                            <template v-slot:item="{ item, on }">
                                                <v-list-item
                                                    v-on="on"
                                                    style="border-bottom: 1px solid grey"
                                                >
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                        {{ item.reason }}
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle> {{ item.category }} </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>

                                            <template v-slot:selection="{ item }">
                                                <div>{{ item.reason }} --- ({{ item.category }} Category)</div>
                                            </template>
                                        </v-select>
                                    </v-col>

                                    <v-col cols="12" sm="3">
                                        <CurrencyInput
                                            v-model="loan_quote_details.entity_monthly_income"
                                            hint="e.g. 7 500"
                                            label="Entity Monthly Income"
                                            :options="currencyOptions"
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="2">
                                        <v-text-field
                                            v-model="loan_quote_details.salary_date"
                                            color="secondary"
                                            label="Salary Date"
                                            type="number"
                                            hint="1 - 31"
                                            persistent-hint
                                            required
                                        ></v-text-field>
                                    </v-col>




                                    <v-col cols="12" sm="4">
                                        <AgentSearch :member="loan_quote_details" label="Agent Who Brought The Loan" />
                                    </v-col>
                                    
                                    <v-col cols="12" sm="3">
                                        <SchemeSearch :parent="loan_quote_details" label="Search Credit Scheme" />
                                    </v-col>

                                    <v-col cols="12" sm="4">
                                        <MemberSearch :member="loan_quote_details" label="Loan Entity" />
                                    </v-col>
                                    <v-col cols="12" sm="1">
                                        <v-tooltip bottom v-if="loan_quote_details.member === null">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="primary white--text"
                                                    fab
                                                    v-on="on"
                                                    @click="openCaptureEntityDialog()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Capture Entity</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                                <br>
                                <br>

                                <!-- LOAN ENTITY DETAILS -->
                                <v-card-text>
                                    <h2 v-if="loan_quote_details.member !== null">Entity Details</h2><br>
                                    <!-- Basic Details -->
                                    <div
                                        v-if="loan_quote_details.member !== null"
                                        style="background:#e9e9e9; border:1px #aaaaaa solid; padding:10px;"
                                    >
                                        <h3>Basic Details</h3>
                                        <v-row>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="loan_quote_details.member.name"
                                                    color="secondary"
                                                    label="Name"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="loan_quote_details.member.surname"
                                                    color="secondary"
                                                    label="Surname"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="getLoanEntityPhone"
                                                    color="secondary"
                                                    label="Phone"
                                                    type="number"
                                                    hint="e.g. 0721234567"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="getLoanEntityEmail"
                                                    color="secondary"
                                                    label="Email"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row style="background:#e0cb99;">
                                            <v-col cols="12" sm="5">
                                                <v-text-field
                                                    v-model="loan_quote_details.member.id_number"
                                                    type="number"
                                                    color="secondary"
                                                    label="ID Number"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <!-- <v-col cols="12" sm="1">
                                                <template>
                                                    <div>
                                                        <span>Id Valid</span><br>
                                                        <span v-if="policy_details.principal_member.id_number_valid === true"><v-icon color="success">mdi-check</v-icon></span>
                                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                                    </div>
                                                </template>
                                            </v-col> -->
                                        </v-row>
                                    </div>
                                    <br>

                                    <!-- Banking Details -->
                                    <div
                                        v-if="(
                                            loan_quote_details.member !== null
                                        )"
                                        style="background:#e9e9e9; border:1px #aaaaaa solid; padding:10px;"
                                    >
                                        <h3>Banking Details</h3>
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <v-radio-group v-model="entity_banking_details">
                                                    <v-radio
                                                        v-for="bankObj in loan_quote_details.member.bank_account_details.filter(bank => { return bank.deleted === false })"
                                                        :key="bankObj._id"
                                                        :label="`${bankObj.bank.name} - ${bankObj.account_number}`"
                                                        :value="bankObj"
                                                        color="secondary"
                                                    ></v-radio>
                                                </v-radio-group>
                                                <h1
                                                    style="color:red"
                                                    v-if="loan_quote_details.member.bank_account_details.filter(bank => { return bank.deleted === false }).length === 0"
                                                >
                                                    No Banking Details Captured
                                                </h1>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <br>

                                    <!-- Payout Banking Details -->
                                    <!-- <div
                                        v-if="(
                                            loan_quote_details.member !== null
                                        )"
                                        style="background:#e9e9e9; border:1px #aaaaaa solid; padding:10px;"
                                    >
                                        <h3>Payout Banking Details</h3>
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <v-radio-group v-model="loan_quote_details.payout_banking">
                                                    <v-radio
                                                        v-for="bankObj in loan_quote_details.member.bank_account_details.filter(bank => { return bank.deleted === false })"
                                                        :key="bankObj._id"
                                                        :label="`${bankObj.bank.name} - ${bankObj.account_number}`"
                                                        :value="bankObj._id"
                                                        color="secondary"
                                                    ></v-radio>
                                                </v-radio-group>
                                                <h1
                                                    style="color:red"
                                                    v-if="loan_quote_details.member.bank_account_details.filter(bank => { return bank.deleted === false }).length === 0"
                                                >
                                                    No Banking Details Captured
                                                </h1>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <br> -->

                                    <!-- Collection Banking Details -->
                                    <!-- <div
                                        v-if="(
                                            loan_quote_details.member !== null &&
                                            loan_quote_details.repayment_config.payment_method === 'DEBIT ORDER'
                                        )"
                                        style="background:#e9e9e9; border:1px #aaaaaa solid; padding:10px;"
                                    >
                                        <h3>Collection Banking Details</h3>
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <v-radio-group v-model="loan_quote_details.collection_banking">
                                                    <v-radio
                                                        v-for="bankObj in loan_quote_details.member.bank_account_details.filter(bank => { return bank.deleted === false })"
                                                        :key="bankObj._id"
                                                        :label="`${bankObj.bank.name} - ${bankObj.account_number}`"
                                                        :value="bankObj._id"
                                                        color="secondary"
                                                    ></v-radio>
                                                </v-radio-group>
                                                <h1
                                                    style="color:red"
                                                    v-if="loan_quote_details.member.bank_account_details.filter(bank => { return bank.deleted === false }).length === 0"
                                                >
                                                    No Banking Details Captured
                                                </h1>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <br> -->

                                    <!-- Physical Address -->
                                    <div
                                        v-if="loan_quote_details.member !== null"
                                        style="background:#e9e9e9; border:1px #aaaaaa solid; padding:10px;"
                                    >
                                        <h3>Physical Address</h3>
                                        <v-row>
                                            <v-col cols="12" sm="4">
                                                <v-text-field
                                                    v-model="getLoanEntityAddress.line1"
                                                    color="secondary"
                                                    label="Line 1"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <v-text-field
                                                    v-model="getLoanEntityAddress.line2"
                                                    color="secondary"
                                                    label="Line 2"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2">
                                                <v-text-field
                                                    v-model="getLoanEntityAddress.line3"
                                                    color="secondary"
                                                    label="Line 3"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2">
                                                <v-text-field
                                                    v-model="getLoanEntityAddress.line4"
                                                    type="number"
                                                    color="secondary"
                                                    label="Postal Code"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <br>

                                    <!-- Documents -->
                                    <!-- <div
                                        v-if="loan_quote_details.member !== null"
                                        style="background:#e9e9e9; border:1px #aaaaaa solid; padding:10px;"
                                    >
                                        <h3>ID Document</h3>
                                        <v-row v-if="policy_details.principal_member.id_doc.server_object !== null">
                                            <v-col cols="12" sm="11">
                                                <v-text-field
                                                    :value="policy_details.principal_member.id_doc.server_object === null ? '' : policy_details.principal_member.id_doc.server_object.name"
                                                    color="secondary"
                                                    label="ID Document"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="1">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            fab
                                                            color="amber"
                                                            v-on="on"
                                                            @click="openViewDocumentDialog()"
                                                            :disabled="policy_details.principal_member.id_doc.server_object === null"
                                                        >
                                                            <v-icon>remove_red_eye</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Preview File</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                        <v-row v-else>
                                            <v-col cols="12" sm="12">
                                                <h1 style="color:red">
                                                    No Id Document Uploaded
                                                </h1>
                                            </v-col>
                                        </v-row>
                                    </div> -->
                                    <br>
                                </v-card-text>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    large
                                    color="amber"
                                    @click="(e1 = 1)"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <!-- <v-btn
                                    large
                                    color="success"
                                    :disabled="submitBtnDisabled()"
                                    @click="submitLoanQuote()"
                                >
                                    <v-icon large>save</v-icon>
                                </v-btn> -->

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            color="success"
                                            x-large
                                            v-on="on"
                                            :disabled="submitBtnDisabled()"
                                            @click="submitLoanQuote()"
                                        >
                                            <v-icon x-large>save</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Submit Quote</span>
                                </v-tooltip>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Submission Results Section -->
                    <v-stepper-content step="3">
                        <v-card>
                            <v-card-text class="text-center" v-if="submitting">
                                Submitting Loan Details
                                <v-progress-linear
                                    indeterminate
                                    color="green"
                                    class="mb-0"
                                    height="5"
                                    :active="submitting"
                                ></v-progress-linear>

                            </v-card-text>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>
        <ViewCalculationResults
            :dialog="open_calc_results_dialog"
            :calculation_results="calulation_results"
        />
        <AddEntity
            :dialog="capture_entity_dialog"
            :identifier="'Loan Borrower'"
        />
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../utils/api";
import AmortizationScheduleItem from "../components/amortization_schedule_item";
import MemberSearch from "../components/member_search";
import SchemeSearch from "../components/scheme_search";
import CurrencyInput from "../../../../components/CurrencyInput.vue";
import AgentSearch from "../components/agent_search";
import ViewCalculationResults from "./view_calculation_results.vue";
import AddEntity from "../../../people/dialogs/onboarding.vue";

import { eventBus } from "../../../../main";
import { payment_methods, BUSINESS_PARTNER_TYPES } from "../../../../../config";

import { calculateLoanPricing, businessProcessLoanQuote } from "../gql/mutations";

import { store } from "../../../../../store";

export default {
    components: {
        MemberSearch,
        SchemeSearch,
        AgentSearch,
        ViewCalculationResults,
        AmortizationScheduleItem,
        AddEntity,
        CurrencyInput,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        open_calc_results_dialog: false,
        capture_entity_dialog: false,
        calulation_results: {},

        currencyOptions: {
            currency: "ZAR",
            currencyDisplay: "symbol",
            hideCurrencySymbolOnFocus: true,
            hideGroupingSeparatorOnFocus: false,
            hideNegligibleDecimalDigitsOnFocus: false,
            autoDecimalDigits: true,
            // valueScaling: "precision",
            useGrouping: true,
            accountingSign: false
        },

        loan_reasons: [
            {
                category: 'Standard',
                reason: 'Housing and related'
            },
            {
                category: 'Standard',
                reason: 'Furniture'
            },
            {
                category: 'Standard',
                reason: 'Small Business'
            },
            {
                category: 'Standard',
                reason: 'Education'
            },
            {
                category: 'Standard',
                reason: 'Debt Consolidation'
            },
            {
                category: 'Standard',
                reason: 'Service'
            },
            {
                category: 'Standard',
                reason: 'Other'
            },
            {
                category: 'Standard',
                reason: 'Refinancing'
            },
            //-------------------------------
            {
                category: 'Emergency',
                reason: 'Death / Funeral'
            },
            {
                category: 'Emergency',
                reason: 'Medical'
            },
            {
                category: 'Emergency',
                reason: 'Income Loss'
            },
            {
                category: 'Emergency',
                reason: 'Loss-Theft or Fire'
            },
            {
                category: 'Emergency',
                reason: 'Other Emergency'
            },
        ],

        loan_types_list: [
            {
                loan_type_id: 1,
                name: 'Personal Loan', // Unsecured Credit
                description: 'No backing assets attached',
                max_annual_interest: 27.75
            },
            // {
            //     loan_type_id: 2,
            //     name: 'Asset Financing',
            //     description: 'e.g. Asset-Backed Loans',
            //     max_annual_interest: 23.75
            // },
            // {
            //     loan_type_id: 3,
            //     name: 'Revolving Credit',
            //     description: 'e.g. Credit Cards, Overdrafts, and Petrol Cards',
            //     max_annual_interest: 20.75
            // },
            // {
            //     loan_type_id: 4,
            //     name: 'Developmental Credit',
            //     description: 'e.g. Business Loans',
            //     max_annual_interest: 33.75
            // },
            // {
            //     loan_type_id: 5,
            //     name: 'Mortage Agreement',
            //     description: 'Mortage Agreement',
            //     max_annual_interest: 18.75
            // },
        ],

        e1: 1,
        entity_banking_details: null,
        loan_quote_details: {
            // Entites
            agent: null,
            member: null,
            scheme: null,

            bank_name: null,
            bank_account_number: null,

            // payout_banking: null,
            // collection_banking: null,
            
            loan_reason: null,
            entity_monthly_income: 0,
            salary_date: 1, // 1 - 31

            repayment_config: {
                payment_method: 'DEBIT ORDER',
                repayment_frequency: 'MONTHLY',
                elected_repayment_time_point: null,
            },
        },
        pricing_details: {
            loan_type: 1,

            principal_amount: 500,
            loan_term_units: 'MONTHS',
            loan_term: 6,

            fees_config: {
                initiation_fee_paid_at_inception: false,
                initiation_fee_principal_percentage: 15,
                monthly_admin_fee: 60,
            },

            specialized_landing: false,

            premium_allocation_priority: {
                principal: 1,
                interest: 2,
                admin_fee_premium: 3,
                initiation_fee_premium: 4,
            },

            interest_config: {
                annual_interest_rate: 0,
                annual_interest_rate_monthly: 0,
            },

            amortization_schedule_config: [
                {
                    id: 1,

                    term_start: 1,
                    term_end: 6,
                    annual_interest_amortization_min_month_term: 1,

                    principal_required: true,
                    initiation_fee_premium_required: true,
                    admin_fee_required: true,
                    interest_required: true,
                }
            ],


            // bank_statement_details: {
            //     salary_1: {
            //         date_on_statement_menu: false,
            //         date_on_statement: null,
            //         balance_1_day_after: 0,
            //         balance_2_days_after: 0,
            //         balance_3_days_after: 0,
            //     },
            //     salary_2: {
            //         date_on_statement_menu: false,
            //         date_on_statement: null,
            //         balance_1_day_after: 0,
            //         balance_2_days_after: 0,
            //         balance_3_days_after: 0,
            //     },
            //     salary_3: {
            //         date_on_statement_menu: false,
            //         date_on_statement: null,
            //         balance_1_day_after: 0,
            //         balance_2_days_after: 0,
            //         balance_3_days_after: 0,
            //     },
            // }

            // bank_statement_details: {
            //     salary_1: {
            //         date_on_statement_menu: false,
            //         date_on_statement: '2023-12-20',
            //         balance_1_day_after: 850,
            //         balance_2_days_after: 800,
            //         balance_3_days_after: 650,
            //     },
            //     salary_2: {
            //         date_on_statement_menu: false,
            //         date_on_statement: '2024-01-20',
            //         balance_1_day_after: 1100,
            //         balance_2_days_after: 950,
            //         balance_3_days_after: 900,
            //     },
            //     salary_3: {
            //         date_on_statement_menu: false,
            //         date_on_statement: '2024-02-20',
            //         balance_1_day_after: 600,
            //         balance_2_days_after: 720,
            //         balance_3_days_after: 700,
            //     },
            // }
        },

        products: [],
        status: 'DRAFT',
        server_response_obj: null,
        submitting: false,

        payment_methods: payment_methods.filter(item => {
            return (
                item === 'CASH' ||
                item === 'DEBIT ORDER' ||
                item === 'EFT' ||
                item === 'STOP ORDER'
            );
        }),


        refreshing_product_lookup_list: false,

        elected_payment_date_rules: [
            v => !!v || 'Elected payment date is required',
            v => (parseFloat(v) > 0 && parseFloat(v) < 32) || 'Must be between 1 and 31'
        ]
    }),
    computed: {
        isCentralUnderwriterAdminUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'admin - central underwriter'
                                                            );
                                                        }).length > 0;

            return result;
        },
        initiationFeeAmount() {
            let initFeeAmount = (
                (parseFloat(this.pricing_details.fees_config.initiation_fee_principal_percentage) / 100) * 
                (parseFloat(this.pricing_details.principal_amount))
            );

            if (isNaN(initFeeAmount)) {
                initFeeAmount = 0;
            }

            // Round to 2 decimal places
            initFeeAmount = Math.round( ( initFeeAmount + Number.EPSILON ) * 100 ) / 100;

            return initFeeAmount;
        },
        monthlyAdminFeePercentage() {
            // Avoid getting infinity
            if (parseFloat(this.pricing_details.principal_amount) === 0) {
                return 0;
            }

            let result = (parseFloat(this.pricing_details.fees_config.monthly_admin_fee) / parseFloat(this.pricing_details.principal_amount)) * 100;

            if (isNaN(result)) {
                result = 0;
            }

            // Round to 2 decimal places
            result = Math.round( ( result + Number.EPSILON ) * 100 ) / 100;

            return result;
        },
        electedRepaymentTimePointValid() {
            // if (
            //     parseFloat(this.loan_quote_details.repayment_config.elected_repayment_time_point) > 0 &&
            //     parseFloat(this.loan_quote_details.repayment_config.elected_repayment_time_point) < 32
            // ) {
            //     return true;
            // }
            // return false;
            return true;
        },
        getLoanEntityAddress() {
            const result = {
                line1: null,
                line2: null,
                line3: null,
                line4: null
            };

            if (this.loan_quote_details.member !== null) {
                const entityAddr = this.loan_quote_details.member.addresses.filter(addr => {
                    return (
                        (
                            addr.type === "Postal + Residential" ||
                            addr.type === "Residential"
                        ) && addr.deleted === false
                    )
                })[0];

                if (entityAddr) {
                    result.line1 = entityAddr.line1;
                    result.line2 = entityAddr.line2;
                    result.line3 = entityAddr.line3;
                    result.line4 = entityAddr.line4;
                }
            }

            return result;
        },
        getLoanEntityPhone() {
            let phone = null;

            if (this.loan_quote_details.member !== null) {
                const entityPhone = this.loan_quote_details.member.contact_details.filter(cont => {
                    return (
                        cont.type === "Mobile" &&
                        cont.primary === true &&
                        cont.deleted === false
                    )
                })[0];

                if (entityPhone) {
                    phone = entityPhone.details;
                }
            }

            return phone;
        },
        getLoanEntityEmail() {
            let phone = null;

            if (this.loan_quote_details.member !== null) {
                const entityEmail = this.loan_quote_details.member.contact_details.filter(cont => {
                    return (
                        cont.type === "Email" &&
                        cont.deleted === false
                    )
                })[0];

                if (entityEmail) {
                    phone = entityEmail.details;
                }
            }

            return phone;
        },
    },
    methods: {
        closeAddLoanDialog() {
            eventBus.$emit('CLOSE_ADD_LOAN_DIALOG', this.server_response_obj);
        },

        getApprovalIcon(approvalResult) {
            let result = {
                icon: null,
                color: null,
            };

            if (approvalResult === 'AUTO-APPROVE') {
                result.icon = 'mdi-check-circle';
                result.color = 'green';
            }
            if (approvalResult === 'AUTO-DECLINE') {
                result.icon = 'mdi-close-circle';
                result.color = 'red';
            }
            if (approvalResult === 'MANUAL') {
                result.icon = 'mdi-minus-circle';
                result.color = 'orange';
            }

            return result;
        },

        addAmortizationScheduleConfigItem() {
            const id = this.pricing_details.amortization_schedule_config.length + 1;
            this.pricing_details.amortization_schedule_config.push(
                {
                    id: id,

                    term_start: 0,
                    term_end: 0,
                    annual_interest_amortization_min_month_term: 1,

                    principal_required: true,
                    initiation_fee_premium_required: true,
                    admin_fee_required: true,
                    interest_required: true,
                }
            );
        },
        removeAmortizationScheduleConfigItem() {
            this.pricing_details.amortization_schedule_config.pop();
        },

        openCaptureEntityDialog() {
            this.capture_entity_dialog = true;
        },
        closeCaptureEntityDialog(newEntity) {
            this.capture_entity_dialog = false;

            if (newEntity !== null) {
                this.pricing_details.member = newEntity;
            }
        },

        async submitCalculation() {
            try {
                const payloadObj = this.extractPricingPayload();
                const res = await this.$apollo
                                            .mutate({
                                                mutation: calculateLoanPricing,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                // if (res.data.calculateLoanPricing.errors === true) {
                //     emitAlert(res.data.calculateLoanPricing.message);
                //     this.submitting = false;
                //     return;
                // }

                // this.server_response_obj = res.data.calculateLoanPricing;
                
                // Show Results Dialog
                this.calulation_results = res.data.calculateLoanPricing;
                this.open_calc_results_dialog = true;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.calculateLoanPricing);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        submitCalculationBtnDisabled() {
            if (parseFloat(this.pricing_details.loan_term) <= 0) {
                return true;
            }

            // Get amortization config items with zero terms
            const zeroTerms = this.pricing_details.amortization_schedule_config.filter(item => {
                return (
                    parseFloat(item.term_start) === 0 ||
                    parseFloat(item.term_end) === 0
                );
            });

            if (zeroTerms.length > 0) {
                return true;
            }

            // Total Periods
            let totalPeriods = 0;
            this.pricing_details.amortization_schedule_config.forEach(item => {
                totalPeriods += (parseFloat(item.term_end) - parseFloat(item.term_start) + 1)
            });

            if (isNaN(totalPeriods)) {
                return true;
            }

            if (totalPeriods !== parseFloat(this.pricing_details.loan_term)) {
                return true;
            }

            // Make sure that the terms are successive
            if (this.pricing_details.amortization_schedule_config.length === 1) {
                if (totalPeriods !== parseFloat(this.pricing_details.loan_term)) {
                    return true;
                }
            } else {
                let result = false;
                
                let currentConfigItem = null;
                this.pricing_details.amortization_schedule_config.forEach(item => {
                    if (currentConfigItem === null) {
                        // Then its a 1st time
                        currentConfigItem = item;
                    } else {
                        // Then its a 2nd time
                        if (currentConfigItem.id !== item.id) { // else, we're dealing the last item
                            const successionTest = parseFloat(item.term_start) - parseFloat(currentConfigItem.term_end);
                            if (successionTest !== 1) {
                                result = true;
                            }
                        }

                        currentConfigItem = item;
                    }
                });

                return result;
            }

            // Make sure there are no annual floating point numbers
            const floatingPtTerms = this.pricing_details.amortization_schedule_config.filter(item => {
                const termStartNum = parseFloat(item.term_start);
                const termEndNum = parseFloat(item.term_end);

                const annualizedPeriod = parseFloat( ((termEndNum - termStartNum + 1) / 12).toFixed(4) );
                const floatingPtPeriod = parseFloat((annualizedPeriod % 1).toFixed(4));

                return (
                    (annualizedPeriod - floatingPtPeriod) > 0 &&
                    floatingPtPeriod > 0
                );
            });

            if (floatingPtTerms.length > 0) {
                return true;
            }

            return false;
        },

        submitBtnDisabled() {
            // ENTITY BANKING DETAILS
            if (this.entity_banking_details === null) {
                return true;
            }

            // LOAN ENTITY
            if (
                this.loan_quote_details.member === null ||
                this.loan_quote_details.agent === null ||
                this.loan_quote_details.scheme === null
            ) {
                return true;
            }

            // LOAN REASON
            if (this.loan_quote_details.loan_reason === null) {
                return true;
            }

            // ---------------------------------
            // ------- MONTHLY-INCOME ----------
            // ---------------------------------
            if (isNaN(parseFloat(this.loan_quote_details.entity_monthly_income))) {
                return true;
            }
            if (parseFloat(this.loan_quote_details.entity_monthly_income) <= 0) {
                return true;
            }

            // ---------------------------------
            // --------- SALARY-DATE -----------
            // ---------------------------------
            if (isNaN(parseFloat(this.loan_quote_details.salary_date))) {
                return true;
            }
            if (
                parseFloat(this.loan_quote_details.salary_date) < 1 &&
                parseFloat(this.loan_quote_details.salary_date) > 31
            ) {
                return true;
            }

            // // ---------------------------------
            // // ----------- SALARY-1 ------------
            // // ---------------------------------
            // if (this.pricing_details.bank_statement_details.salary_1.date_on_statement === null) {
            //     return true;
            // }
            // // Day-1 Balance
            // if (isNaN(parseFloat(this.pricing_details.bank_statement_details.salary_1.balance_1_day_after))) {
            //     return true;
            // }
            // // Day-2 Balance
            // if (isNaN(parseFloat(this.pricing_details.bank_statement_details.salary_1.balance_2_days_after))) {
            //     return true;
            // }
            // // Day-3 Balance
            // if (isNaN(parseFloat(this.pricing_details.bank_statement_details.salary_1.balance_3_days_after))) {
            //     return true;
            // }

            // // ---------------------------------
            // // ----------- SALARY-2 ------------
            // // ---------------------------------
            // if (this.pricing_details.bank_statement_details.salary_2.date_on_statement === null) {
            //     return true;
            // }
            // // Day-1 Balance
            // if (isNaN(parseFloat(this.pricing_details.bank_statement_details.salary_2.balance_1_day_after))) {
            //     return true;
            // }
            // // Day-2 Balance
            // if (isNaN(parseFloat(this.pricing_details.bank_statement_details.salary_2.balance_2_days_after))) {
            //     return true;
            // }
            // // Day-3 Balance
            // if (isNaN(parseFloat(this.pricing_details.bank_statement_details.salary_2.balance_3_days_after))) {
            //     return true;
            // }

            // // ---------------------------------
            // // ----------- SALARY-3 ------------
            // // ---------------------------------
            // if (this.pricing_details.bank_statement_details.salary_3.date_on_statement === null) {
            //     return true;
            // }
            // // Day-1 Balance
            // if (isNaN(parseFloat(this.pricing_details.bank_statement_details.salary_3.balance_1_day_after))) {
            //     return true;
            // }
            // // Day-2 Balance
            // if (isNaN(parseFloat(this.pricing_details.bank_statement_details.salary_3.balance_2_days_after))) {
            //     return true;
            // }
            // // Day-3 Balance
            // if (isNaN(parseFloat(this.pricing_details.bank_statement_details.salary_3.balance_3_days_after))) {
            //     return true;
            // }

            return false;
        },

        resetForm() {
            this.server_response_obj = null;

            this.e1 = 1;
            this.submitting = false;

            this.loan_quote_details = {
                // Entites
                agent: null,
                member: null,
                scheme: null,
                loan_reason: null,
                entity_monthly_income: 0,

                bank_name: null,
                bank_account_number: null,

                // payout_banking: null,
                // collection_banking: null,

                repayment_config: {
                    payment_method: 'DEBIT ORDER',
                    repayment_frequency: 'MONTHLY',
                    elected_repayment_time_point: null,
                },
            };
            this.pricing_details = {
                loan_type: 1,

                principal_amount: 500,
                loan_term_units: 'MONTHS',
                loan_term: 6,

                fees_config: {
                    initiation_fee_paid_at_inception: false,
                    initiation_fee_principal_percentage: 15,
                    monthly_admin_fee: 60,
                },

                specialized_landing: false,

                premium_allocation_priority: {
                    principal: 1,
                    interest: 2,
                    admin_fee_premium: 3,
                    initiation_fee_premium: 4,
                },

                interest_config: {
                    annual_interest_rate: 0,
                    annual_interest_rate_monthly: 0,
                },

                amortization_schedule_config: [
                    {
                        id: 1,

                        term_start: 1,
                        term_end: 6,
                        annual_interest_amortization_min_month_term: 1,

                        principal_required: true,
                        initiation_fee_premium_required: true,
                        admin_fee_required: true,
                        interest_required: true,
                    }
                ],

                // bank_statement_details: {
                //     salary_1: {
                //         date_on_statement_menu: false,
                //         date_on_statement: null,
                //         balance_1_day_after: 0,
                //         balance_2_days_after: 0,
                //         balance_3_days_after: 0,
                //     },
                //     salary_2: {
                //         date_on_statement_menu: false,
                //         date_on_statement: null,
                //         balance_1_day_after: 0,
                //         balance_2_days_after: 0,
                //         balance_3_days_after: 0,
                //     },
                //     salary_3: {
                //         date_on_statement_menu: false,
                //         date_on_statement: null,
                //         balance_1_day_after: 0,
                //         balance_2_days_after: 0,
                //         balance_3_days_after: 0,
                //     },
                // }
            };

            // Re-Trigger loan type changed
            this.loanTypeChanged( this.pricing_details.loan_type );
        },

        async submitLoanQuote() {
            // Then submit the loan
            this.submitting = true;
            this.e1 = 3;
            
            // Prepare payload
            const quotePayloadObj = this.extractQuotePayload();

            // if (quotePayloadObj !== null) {
            //     console.log(quotePayloadObj)
            //     return;
            // }

            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessLoanQuote,
                                                variables: {
                                                    input: quotePayloadObj
                                                },
                                            });
                this.submitting = false;
                if (res.data.businessProcessLoanQuote.errors === true) {
                    emitAlert(res.data.businessProcessLoanQuote.message);
                    return;
                }

                this.server_response_obj = res.data.businessProcessLoanQuote.business_object;

                emitSuccess(res.data.businessProcessLoanQuote.message);

                // Show Results Dialog
                this.closeAddLoanDialog();
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessLoanQuote);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        extractPricingPayload() {
            // const loanTypeObj = this.loan_types_list.filter(item => { return item.loan_type_id === this.pricing_details.loan_type })[0];
            const payloadObj = {
                    loan_type: this.pricing_details.loan_type,

                    principal_amount: parseFloat(this.pricing_details.principal_amount),
                    loan_term_units: this.pricing_details.loan_term_units,
                    loan_term: parseFloat(this.pricing_details.loan_term),

                    fees_config: {
                        monthly_admin_fee: parseFloat(this.pricing_details.fees_config.monthly_admin_fee),
                        initiation_fee_principal_percentage: parseFloat(this.pricing_details.fees_config.initiation_fee_principal_percentage),
                        initiation_fee_paid_at_inception: this.pricing_details.fees_config.initiation_fee_paid_at_inception,
                    },

                    specialized_landing: this.pricing_details.specialized_landing,

                    premium_allocation_priority: {
                        principal: parseInt(this.pricing_details.premium_allocation_priority.principal),
                        interest: parseInt(this.pricing_details.premium_allocation_priority.interest),
                        admin_fee_premium: parseInt(this.pricing_details.premium_allocation_priority.admin_fee_premium),
                        initiation_fee_premium: parseInt(this.pricing_details.premium_allocation_priority.initiation_fee_premium)
                    },

                    interest_config: {
                        annual_interest_rate: parseFloat(this.pricing_details.interest_config.annual_interest_rate),
                        annual_interest_rate_monthly: parseFloat(this.pricing_details.interest_config.annual_interest_rate_monthly),

                    },
                    amortization_schedule_config: this.pricing_details.amortization_schedule_config.map(conf => {
                        const termStart = (this.pricing_details.specialized_landing === false) ? 1 : parseInt(conf.term_start);
                        const termEnd = (this.pricing_details.specialized_landing === false) ? parseInt(this.pricing_details.loan_term) : parseInt(conf.term_end);
                        return {
                            // term_start: parseInt(conf.term_start),
                            // term_end: parseInt(conf.term_end),
                            term_start: termStart,
                            term_end: termEnd,
                            annual_interest_amortization_min_month_term: parseInt(conf.annual_interest_amortization_min_month_term),

                            principal_required: conf.principal_required,
                            initiation_fee_premium_required: conf.initiation_fee_premium_required,
                            admin_fee_required: conf.admin_fee_required,
                            interest_required: conf.interest_required,
                        };
                    }),
                };

            return payloadObj;
        },

        extractQuotePayload() {
            const pricingPayloadObj = this.extractPricingPayload();

            const result = {
                OP_TYPE: 'CREATE',
                // _id: null,
                
                agent: this.loan_quote_details.agent !== null ? this.loan_quote_details.agent._id : null,
                entity: this.loan_quote_details.member !== null ? this.loan_quote_details.member._id : null,
                scheme: this.loan_quote_details.scheme !== null ? this.loan_quote_details.scheme._id : null,
                
                loan_reason: this.loan_quote_details.loan_reason.reason,
                loan_reason_category: this.loan_quote_details.loan_reason.category,
                entity_monthly_income: parseFloat(this.loan_quote_details.entity_monthly_income),

                // collection_banking: this.loan_quote_details.collection_banking,
                // payout_banking: this.loan_quote_details.payout_banking,

                banking_details_id: this.entity_banking_details._id,
                bank_name: this.entity_banking_details.bank.name,
                bank_account_number: this.entity_banking_details.account_number,

                pricing_input: pricingPayloadObj,

                repayment_info: {
                    payment_method: this.loan_quote_details.repayment_config.payment_method,
                    repayment_frequency: this.loan_quote_details.repayment_config.repayment_frequency,
                    elected_repayment_time_point: parseInt(this.loan_quote_details.repayment_config.elected_repayment_time_point),
                },

                // bank_statement_details: {
                //     salary_date: parseFloat(this.loan_quote_details.salary_date),
                //     salary_1: {
                //         date_on_statement: this.pricing_details.bank_statement_details.salary_1.date_on_statement,
                //         balance_1_day_after: this.pricing_details.bank_statement_details.salary_1.balance_1_day_after,
                //         balance_2_days_after: this.pricing_details.bank_statement_details.salary_1.balance_2_days_after,
                //         balance_3_days_after: this.pricing_details.bank_statement_details.salary_1.balance_3_days_after,
                //     },
                //     salary_2: {
                //         date_on_statement: this.pricing_details.bank_statement_details.salary_2.date_on_statement,
                //         balance_1_day_after: this.pricing_details.bank_statement_details.salary_2.balance_1_day_after,
                //         balance_2_days_after: this.pricing_details.bank_statement_details.salary_2.balance_2_days_after,
                //         balance_3_days_after: this.pricing_details.bank_statement_details.salary_2.balance_3_days_after,
                //     },
                //     salary_3: {
                //         date_on_statement: this.pricing_details.bank_statement_details.salary_3.date_on_statement,
                //         balance_1_day_after: this.pricing_details.bank_statement_details.salary_3.balance_1_day_after,
                //         balance_2_days_after: this.pricing_details.bank_statement_details.salary_3.balance_2_days_after,
                //         balance_3_days_after: this.pricing_details.bank_statement_details.salary_3.balance_3_days_after,
                //     },
                // }
            };


            return result;
        },

        loanTypeChanged(loanTypeId) {
            const loanTypeObj = this.loan_types_list.filter(item => { return item.loan_type_id === loanTypeId })[0];
            this.pricing_details.interest_config.annual_interest_rate = loanTypeObj.max_annual_interest;
            this.pricing_details.interest_config.annual_interest_rate_monthly = Math.round( ( (loanTypeObj.max_annual_interest / 12) + Number.EPSILON ) * 100 ) / 100;
        },
        updateInitFee(operation) {
            if (operation === 'plus') {
                this.pricing_details.fees_config.initiation_fee_principal_percentage += 0.5;
            }

            if (operation === 'minus') {
                this.pricing_details.fees_config.initiation_fee_principal_percentage -= 0.5;
            }
        },
        updateMonthlyAdminFee(operation) {
            if (operation === 'plus') {
                this.pricing_details.fees_config.monthly_admin_fee += 0.5;
            }

            if (operation === 'minus') {
                this.pricing_details.fees_config.monthly_admin_fee -= 0.5;
            }
        },

        getTermPeriod(scheduleConfig) {
            const result = (parseFloat(scheduleConfig.term_end) - parseFloat(scheduleConfig.term_start)) + 1;
            return result;
        }
    },
    async mounted() {
        eventBus.$on('CLOSE_VIEW_LOAN_CALCULATION_RESULTS_DIALOG', () => { this.open_calc_results_dialog = false; });
        eventBus.$on('CLOSE_ONBOARDING_DIALOG', this.closeCaptureEntityDialog);

        // Load interest rate
        this.loanTypeChanged( this.pricing_details.loan_type );
    },
    watch: {
        'pricing_details.specialized_landing': function(val) {
            if (val === false) {
                this.pricing_details.amortization_schedule_config = [
                    {
                        id: 1,

                        term_start: 1,
                        term_end: this.pricing_details.loan_term,
                        annual_interest_amortization_min_month_term: 1,

                        principal_required: true,
                        initiation_fee_premium_required: true,
                        admin_fee_required: true,
                        interest_required: true,
                    }
                ];
            } else {
                // this.pricing_details.amortization_schedule_config[0] = {
                //     // term_start: 0,
                //     // term_end: 0,
                //     term_start: 1,
                //     term_end: this.pricing_details.loan_term,
                //     annual_interest_amortization_min_month_term: 5,

                //     principal_required: true,
                //     initiation_fee_premium_required: true,
                //     admin_fee_required: true,
                //     interest_required: true,
                // }
            }
        },

        'pricing_details.loan_term': function(val) {
            const valNum = parseFloat(val);

            if (this.pricing_details.specialized_landing === false) {
                this.pricing_details.amortization_schedule_config[0].term_start = 1;
                this.pricing_details.amortization_schedule_config[0].term_end = valNum;
            }
        },

        'loan_quote_details.member': function(val) {
            if (val === null) {
                // this.loan_quote_details.payout_banking = null;
                // this.loan_quote_details.collection_banking = null;

                this.loan_quote_details.bank_name = null;
                this.loan_quote_details.bank_account_number = null;
            }
        },


        // 'pricing_details.amount': function(val) {
        //     if (
        //         isNaN(parseFloat(val)) ||
        //         isNaN(parseFloat(this.pricing_details.installments)) ||
        //         parseFloat(val) === 0 ||
        //         parseFloat(this.pricing_details.installments) === 0
        //     ) {
        //         this.pricing_details.premium = 0;
        //         return;
        //     }

        //     const rawPremium = (this.pricing_details.amount / this.pricing_details.installments) +
        //         this.pricing_details.product.primary_product.monthly_fixed_fee;

        //     // Round off to 2 decimal places
        //     this.pricing_details.premium = Math.round((rawPremium + Number.EPSILON) * 100) / 100;
        // },

        // 'pricing_details.installments': function(val) {
        //     if (
        //         isNaN(parseFloat(val)) ||
        //         isNaN(parseFloat(this.pricing_details.amount)) ||
        //         parseFloat(val) === 0 ||
        //         parseFloat(this.pricing_details.amount) === 0
        //     ) {
        //         this.pricing_details.premium = 0;
        //         return;
        //     }

        //     const rawPremium = (this.pricing_details.amount / this.pricing_details.installments) +
        //         this.pricing_details.product.primary_product.monthly_fixed_fee;

        //     // Round off to 2 decimal places
        //     this.pricing_details.premium = Math.round((rawPremium + Number.EPSILON) * 100) / 100;
        // },

    }
}
</script>
<style scoped>

</style>