import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from './pages/login/login.vue';
import Users from './pages/users/users.vue';
import UserRoles from './pages/user_roles/user_roles.vue';
import UserScreens from './pages/user_screens/user_screens.vue';
import Policies from './pages/policies/policies.vue';
import UnderwriterBatchPayments from './pages/underwriter_batch_payments/underwriter_batch_payments.vue';
// import CashAdvance from './pages/cash_advance/cash_advance.vue';
import LoanQuote from './pages/prospects/credit/loan_quote.vue';
import Loan from './pages/loan/loan.vue';
// import LoanV2 from './pages/loanv2/loan.vue';
import CollectionsManagement from './pages/collections_management/collections_management.vue';
import CorporateBond from './pages/corporate_bond/corporate_bond.vue';
import OperationalExcellence from './pages/operational_excellence/operational_excellence.vue';
import People from './pages/people/people.vue';
import ProductRuleParameters from './pages/lookups/product_rule_parameters/product_rule_parameters.vue';
import Scheme from './pages/scheme/scheme.vue';
import BusinessPartner from './pages/business_partner/business_partner.vue';
import Agents from './pages/agents/agents.vue';
import Reports from './pages/reports/reports.vue';
import Dashboard from './pages/dashboard.vue';
import Branches from './pages/branches/branches.vue';

// import Policies_V2 from './pages/policies_v2/policies.vue';
import SchemeAllocation from './pages/scheme_allocation/scheme_allocation.vue';
import StatementClassification from './pages/management_accounts/classifications/classifications.vue';
import BankStatement from './pages/management_accounts/bank_statement/bank_statement.vue';
import BankFeed_MainAcc from './pages/management_accounts/bank_feed_main/bank_feed_main_acc.vue';
import BankFeed_CorpBondAcc from './pages/management_accounts/bank_feed_corp_bond/bank_feed_corp_bond_acc.vue';
import BankFeed_ReservesAcc from './pages/management_accounts/bank_feed_reserves/bank_feed_reserves.vue';
import SoftyCompFeed from './pages/management_accounts/softy_comp_feed/softy_comp_feed.vue';

// Prospects
import ProspectPolicies from './pages/prospects/insurance/policy/PolicyProspect.vue';

// Insurance Product
import InsuranceProductPrimary from './pages/products/insurance/product_primary/insurance_product_primary.vue';
import InsuranceProductSecondary from './pages/products/insurance/product_secondary/insurance_product_secondary.vue';
// // Insurance Product
import CreditProductPrimary from './pages/products/credit/product_primary/credit_product_primary.vue';
// import CreditProductSecondary from './pages/products/credit/product_secondary/credit_product_secondary.vue';

import FinancialCalendar from './pages/finance/fin_calendar/fin_calendar.vue';
import SystemAccounts from './pages/finance/system_accounts/system_accounts.vue';
import BusinessProcesses from './pages/business_processes/business_processes.vue';

// Lookups
import BankLookup from './pages/lookups/bank_list/bank_list.vue';
import ReportLookup from './pages/lookups/reports_list/reports_list.vue';
import RelationshipTypeLookup from './pages/lookups/entity_relationship_types/rel_type_list.vue';
import PayoutAgeCategoryLookup from './pages/lookups/payout_age_category/payout_age_category.vue';
import UnderwriterLookup from './pages/lookups/underwriter/underwriter.vue';
import VASServicesLookup from './pages/lookups/vas/vas_list.vue';
import DashboardsLookup from './pages/lookups/dashboards/dashboards_list.vue';
import BusinessProcessList from './pages/lookups/business_process_list/business_process_list.vue';


Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Dashboard,
            meta: { keepAlive: true }
        },
        {
            path: '/operational_excellence/',
            component: OperationalExcellence,
            meta: { keepAlive: true }
        },
        {
            path: '/reports/',
            component: Reports,
            meta: { keepAlive: true }
        },
        {
            path: '/auth/',
            component: Login,
        },
        {
            path: '/users/',
            component: Users,
            meta: { keepAlive: true }
        },
        {
            path: '/user_roles/',
            component: UserRoles,
            meta: { keepAlive: true }
        },
        {
            path: '/user_screens/',
            component: UserScreens,
            meta: { keepAlive: true }
        },
        {
            path: '/policies/',
            component: Policies,
            meta: { keepAlive: true }
        },
        {
            path: '/underwriter_batch_payments/',
            component: UnderwriterBatchPayments,
            meta: { keepAlive: true }
        },
        // {
        //     path: '/cash_advance/',
        //     component: CashAdvance,
        //     meta: { keepAlive: true }
        // },
        {
            path: '/loan_quote/',
            component: LoanQuote,
            meta: { keepAlive: true }
        },
        {
            path: '/loan/',
            component: Loan,
            meta: { keepAlive: true }
        },
        {
            path: '/collections-management/',
            component: CollectionsManagement,
            meta: { keepAlive: true }
        },
        // {
        //     path: '/loanv2/',
        //     component: LoanV2,
        //     meta: { keepAlive: true }
        // },
        // {
        //     path: '/management_accounts_monthly/',
        //     component: Loan,
        //     meta: { keepAlive: true }
        // },
        // {
        //     path: '/management_accounts_yearly/',
        //     component: Loan,
        //     meta: { keepAlive: true }
        // },
        {
            path: '/corporate_bond/',
            component: CorporateBond,
            meta: { keepAlive: true }
        },
        {
            path: '/people/',
            component: People,
            meta: { keepAlive: true }
        },
        {
            path: '/agents/',
            component: Agents,
            meta: { keepAlive: true }
        },
        {
            path: '/schemes/',
            component: Scheme,
            meta: { keepAlive: true }
        },
        // {
        //     path: '/policies_v2/',
        //     component: Policies_V2,
        //     meta: { keepAlive: true }
        // },
        {
            path: '/scheme_allocations/',
            component: SchemeAllocation,
            meta: { keepAlive: true }
        },
        {
            path: '/statement_classification/',
            component: StatementClassification,
            meta: { keepAlive: true }
        },
        {
            path: '/bank_statements/',
            component: BankStatement,
            meta: { keepAlive: true }
        },
        {
            path: '/bank_feed_main_acc/',
            component: BankFeed_MainAcc,
            meta: { keepAlive: true }
        },
        {
            path: '/bank_feed_corp_bond_acc/',
            component: BankFeed_CorpBondAcc,
            meta: { keepAlive: true }
        },
        {
            path: '/bank_feed_reserves_acc/',
            component: BankFeed_ReservesAcc,
            meta: { keepAlive: true }
        },
        {
            path: '/softy_comp_feed/',
            component: SoftyCompFeed,
            meta: { keepAlive: true }
        },
        {
            path: '/business_partners/',
            component: BusinessPartner,
            meta: { keepAlive: true }
        },
        {
            path: '/branches/',
            component: Branches,
            meta: { keepAlive: true }
        },


        // --------------- Prospects -------------- //
        {
            path: '/policy_prospects/',
            component: ProspectPolicies,
            meta: { keepAlive: true }
        },
        
        
        // ---------------- Finance --------------- //
        {
            path: '/financial_calendar/',
            component: FinancialCalendar,
            meta: { keepAlive: true }
        },
        {
            path: '/system_accounts/',
            component: SystemAccounts,
            meta: { keepAlive: true }
        },
        
        // ---------------- Business Processes --------------- //,
        {
            path: '/business_processes/',
            component: BusinessProcesses,
            meta: { keepAlive: true }
        },


        // ---------------- Lookups --------------- //
        {
            path: '/banks-lookup/',
            component: BankLookup,
            meta: { keepAlive: true }
        },
        {
            path: '/reports-lookup/',
            component: ReportLookup,
            meta: { keepAlive: true }
        },
        {
            path: '/reltype-lookup/',
            component: RelationshipTypeLookup,
            meta: { keepAlive: true }
        },
        {
            path: '/payout-age-cat-lookup/',
            component: PayoutAgeCategoryLookup,
            meta: { keepAlive: true }
        },
        {
            path: '/underwriter/',
            component: UnderwriterLookup,
            meta: { keepAlive: true }
        },
        {
            path: '/product_rule_parameters/',
            component: ProductRuleParameters,
            meta: { keepAlive: true }
        },
        {
            path: '/vas/',
            component: VASServicesLookup,
            meta: { keepAlive: true }
        },
        {
            path: '/business_process_list/',
            component: BusinessProcessList,
            meta: { keepAlive: true }
        },
        {
            path: '/dashboards/',
            component: DashboardsLookup,
            meta: { keepAlive: true }
        },
        
        // ---------------- Insurance Products --------------- //
        {
            path: '/insurance/product/primary/',
            component: InsuranceProductPrimary,
            meta: { keepAlive: true }
        },
        {
            path: '/insurance/product/secondary/',
            component: InsuranceProductSecondary,
            meta: { keepAlive: true }
        },

        // ---------------- Credit Products --------------- //
        {
            path: '/credit/product/primary/',
            component: CreditProductPrimary,
            meta: { keepAlive: true }
        },
        // {
        //     path: '/credit/product/secondary/',
        //     component: CreditProductSecondary,
        //     meta: { keepAlive: true }
        // },


        //   {
            //     path: '/accounts',
            //     name: 'accounts',
            //     component: Accounts,
            //     redirect: 'accounts/all',
            //     children: [
                //       {
                    //         path: 'all',
                    //         name: 'all',
                    //         component: AllAccounts,
    //       },
    //       {
    //         path: 'transactions/:id',
    //         name: 'transactions',
    //         component: Transactions,
    //       },
    //     ],
    //   },
    ],
  });
  