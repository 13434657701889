<template>
    <div>
        <!-------------------------- APP BAR -------------------------->
        <v-app-bar
            v-if="!hide"
            app
            clipped-left
            color="secondary"
            height="50"
        >
            <v-app-bar-nav-icon
                color="amber"
                @click="openDrawer()"
            ></v-app-bar-nav-icon>
            <span
                class="title ml-3 mr-5"
            >
                <span style="color:#ffffff">
                Afrosurance&nbsp;<span class="font-weight-light">Portal</span>
                </span>
            </span>
            <v-spacer></v-spacer>

            <v-row>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="red white--text"
                                small
                                @click="deepRefresh()"
                                v-on="on"
                            >
                                <v-icon style="padding-right:20px">refresh</v-icon>
                                DEEP REFRESH
                            </v-btn>
                        </template>
                        <span>Pull new profile data if new screens are now assigned to you</span>
                    </v-tooltip>
                </v-col>
                <v-col
                    v-if="display_context_selector"
                    cols="12"
                    sm="6"
                >
                    <v-tooltip bottom v-if="display_context_selector">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="primary white--text"
                                small
                                @click="openBusinessProcessTriggerDialog()"
                                v-on="on"
                            >
                                <v-icon style="padding-right:20px">refresh</v-icon>
                                Trigger Process
                            </v-btn>
                        </template>
                        <span>Select a backend business process you want to trigger</span>
                    </v-tooltip>
                </v-col>
            </v-row>

            <v-spacer></v-spacer>
            
            <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    offset-sm="6"
                >
                    <v-select
                        v-if="display_context_selector"
                        class="title"
                        style="background: #f6c20b;  border-radius:5px; height:60%; top:10px; padding-left:10px"
                        v-model="selected_context"
                        @change="contextChange"
                        color="white"
                        :items="context_list"
                        item-text="business_partner.name"
                        item-value="business_partner._id"
                        :disabled="refreshing_context_lookup_list"
                    >
                        <!-- Format prepend inner icon -->
                        <template v-slot:prepend-inner>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        v-on="on"
                                        color="success"
                                        @click="refreshContextLookupList()"
                                    >
                                        mdi-refresh
                                    </v-icon>
                                </template>
                                <span>Refresh Context List</span>
                            </v-tooltip>
                        </template>
                    </v-select>
                    <v-progress-linear
                        color="green"
                        class="mb-0"
                        height="4"
                        indeterminate
                        :active="refreshing_context_lookup_list"
                    ></v-progress-linear>
                </v-col>
            </v-row>
        </v-app-bar>

        <!-------------------------- NAVIGATION DRAWER -------------------------->
        <v-navigation-drawer
            v-if="!hide"
            v-model="drawer"
            app
            clipped
            color="grey lighten-4"
            width="300"
        >
            <!-- User Profile Details -->
            <v-list>
                <v-list-item class="px-2" style="border-bottom: 1px solid #9e9e9e">
                    <!-- <v-list-item-avatar color="amber">
                        <v-icon>mdi-account</v-icon>
                    </v-list-item-avatar> -->
                    <v-list-item-content color="amber">
                        <v-img src="../public/afrosurance-logo.png" width="40%"/>
                        <!-- <v-icon>mdi-account</v-icon> -->
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link style="border-bottom: 1px solid #9e9e9e">
                    <v-list-item-content>
                        <!-- <h3>Afrosurance Pty (Ltd)</h3> -->
                        <v-list-item-subtitle>Afrosurance Pty (Ltd)</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link>
                    <v-list-item-content>
                        <!-- <v-list-item-title class="title">{{ currentUserAccount.entity.name + ' ' + currentUserAccount.entity.surname }}</v-list-item-title> -->
                        <h3>{{ currentUserAccount.entity.name + ' ' + currentUserAccount.entity.surname }}</h3>
                        <v-list-item-subtitle>{{ currentUserAccount.entity.id_number }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-divider></v-divider>
            <v-list
                dense
                class="grey lighten-4"
            >
                <template v-for="(item, i) in items">
                    <!-- Menu Item Heading -->
                    <v-row
                        v-if="item.heading"
                        :key="i + '-heading-' + item.heading"
                        align="center"
                    >
                        <v-col cols="12" style="padding-top:0px">
                            <v-subheader v-if="item.heading && item.display===true">
                                <b>{{ item.heading }}</b>
                            </v-subheader>
                        </v-col>
                    </v-row>
                    <!-- Menu item divider -->
                    <v-divider
                        v-if="item.divider === true && item.display === true"
                        :key="i  + '-divider'"
                        dark
                        class="my-4"
                    ></v-divider>
                    <!-- Menu item -->
                    <div v-else-if="item.display === true && item.path" :key="i + 'menu'">
                        <v-list-item
                            link
                            @click="navigate({path: item.path, title: item.title})"
                            :style="$route.path===item.path ? 'background: #f6c209' : ''"
                        >
                            <v-list-item-action>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </template>
            </v-list>
            <template v-slot:append>
                <v-list 
                    nav
                    dense
                    absolute
                    class="side-bar side-bar--bottom"
                >
                    <!-- <v-list-item link @click="deepRefresh()" style="background: red; color: white;">
                        <v-list-item-icon>
                            <v-icon color="white">refresh</v-icon>
                        </v-list-item-icon>

                        <v-list-item-title style="color: white">DEEP REFRESH</v-list-item-title>
                    </v-list-item> -->
                    <v-list-item link @click="userLogout()" style="background: #4590a0; color: white;">
                        <v-list-item-icon>
                            <v-icon color="white">power_settings_new</v-icon>
                        </v-list-item-icon>

                        <v-list-item-title style="color: white">LOGOUT</v-list-item-title>
                    </v-list-item>
                </v-list>
            </template>
        </v-navigation-drawer>

        <!-- Context switch overlay -->
        <v-overlay :value="switching_context">
          <v-progress-circular
            color="white"
            indeterminate
            size="150"
            width="9"
          >
            <h3 class="text-center">Switching Context</h3>
          </v-progress-circular>
        </v-overlay>
        <v-overlay :value="deep_refreshing">
          <v-progress-circular
            color="white"
            indeterminate
            size="150"
            width="9"
          >
            <h3 class="text-center">Deep Refreshing</h3>
          </v-progress-circular>
        </v-overlay>

        <TriggerBusinessProcessDialog :dialog="open_business_process_trigger_dialog"/>
    </div>
</template>

<script>
import auth from "./mixins/auth";
import { eventBus } from './main';
import { store } from "../store";
import config from '../config';
import { emitAlert } from './utils/api';
import TriggerBusinessProcessDialog from './root_dialogs/trigger_business_process.vue';


import { authSwitchContext, authDeepRefresh } from "./root_gql/mutations";

export default {
    name: 'AppNav',
    mixins: [auth],
    components: {
        TriggerBusinessProcessDialog
    },

    data: () => ({
        menu_permanent: false,
        progressBar: true,
        tabItems: store.state.tabItems,
        currentUserAccount: store.state.currentUserAccount,
        user_permitted_screens: [],
        switching_context: false,
        deep_refreshing: false,

        context_list: store.state.context_list,
        selected_context: null,
        display_context_selector: false,

        open_business_process_trigger_dialog: false,

        drawer: false,
        items: [
            { heading: 'Home', display: true },
            { icon: 'mdi-home', text: 'Home / Dashboard', title: 'Dashboard', path: '/', display: false },
            { icon: 'mdi-star', text: 'Operational Excellence', title: 'Operational Excellence', path: '/operational_excellence', display: false },
            { divider: true, display: false, section: 'Dashboard' },
            
            { heading: 'Central Underwriting', display: false },
            { icon: 'mdi-handshake', text: 'Business Partners', title: 'Business Partners', path: '/business_partners/', display: false },
            { icon: 'mdi-pencil-box-outline', text: 'External Underwriters', title: 'External Underwriters', path: '/underwriter/', display: false },
            { icon: 'mdi-view-list', text: 'Insurance Primary Products', title: 'Insurance Primary Products', path: '/insurance/product/primary/', display: false },
            { icon: 'mdi-view-list', text: 'Credit Primary Products', title: 'Credit Primary Products', path: '/credit/product/primary/', display: false },
            { icon: 'mdi-cash-multiple', text: 'Business Processes', title: 'Business Processes', path: '/business_processes/', display: false },
            { icon: 'mdi-source-branch', text: 'Afrosurance Branches', title: 'Afrosurance Branches', path: '/branches/', display: false },
            { icon: 'mdi-cash', text: 'Collections Management', title: 'Collections Management', path: '/collections-management/', display: false },
            { divider: true, display: false, section: 'Central Underwriting' },

            { heading: 'Core Insurance System', display: false },
            { icon: 'supervisor_account', text: 'People', title: 'People', path: '/people/', display: false },
            { icon: 'policy', text: 'Prospect Policies', title: 'Prospect Policies', path: '/policy_prospects/', display: false },
            { icon: 'policy', text: 'Policies', title: 'Policies', path: '/policies/', display: false },
            { icon: 'policy', text: 'Underwriter Batch Payments', title: 'Underwriter Batch Payments', path: '/underwriter_batch_payments/', display: false },
            // { icon: 'policy', text: 'Policies-V2', title: 'Policies-V2', path: '/policies_v2/', display: true },
            // { icon: 'money', text: 'Claims', title: 'Claims', path: '/claims/', display: false },
            { icon: 'group_work', text: 'Schemes', title: 'Schemes', path: '/schemes/', display: false },
            { icon: 'group_work', text: 'Scheme Allocations', title: 'Scheme Allocations', path: '/scheme_allocations/', display: false },
            { icon: 'mdi-view-list', text: 'Insurance Secondary Products', title: 'Insurance Secondary Products', path: '/insurance/product/secondary/', display: false },
            { icon: 'mdi-account-check', text: 'Agents', title: 'Agents', path: '/agents/', display: false },
            // { icon: 'mdi-account-key', text: 'Directors', title: 'Directors', path: '/directors/', display: false },
            { icon: 'mdi-chart-bar', text: 'Reports', title: 'Reports', path: '/reports/', display: false },
            { divider: true, display: false, section: 'Core Insurance System' },

            { heading: 'Credit', display: false },
            { icon: 'mdi-cash', text: 'Loan Quote', title: 'Loan Quote', path: '/loan_quote/', display: false },
            { icon: 'mdi-cash', text: 'Loan', title: 'Loan', path: '/loan/', display: false },
            // { icon: 'mdi-cash', text: 'LoanV2', title: 'LoanV2', path: '/loanv2/', display: true },
            // { icon: 'mdi-cash', text: 'Cash Advance', title: 'Cash Advance', path: '/cash_advance/', display: false },
            // { icon: 'mdi-view-list', text: 'Credit Secondary Products', title: 'Credit Secondary Products', path: '/credit/product/secondary/', display: false },
            { icon: 'mdi-view-list', text: 'Corporate Bond', title: 'Corporate Bond', path: '/corporate_bond/', display: false },
            { divider: true, display: false, section: 'Credit' },

            { heading: 'Finance Management', display: false },
            { icon: 'mdi-calendar-range', text: 'Financial Calendar', title: 'Financial Calendar', path: '/financial_calendar/', display: false },
            { icon: 'mdi-cash-multiple', text: 'System Accounts', title: 'System Accounts', path: '/system_accounts/', display: false },
            // { icon: 'mdi-cash-register', text: 'Management Accounts - Monthly', title: 'Management Accounts - Monthly', path: '/management_accounts_monthly/', display: true },
            // { icon: 'mdi-cash-register', text: 'Management Accounts - Yearly', title: 'Management Accounts - Yearly', path: '/management_accounts_yearly/', display: true },
            { divider: true, display: false, section: 'Finance Management'},

            { heading: 'Management Accounts', display: false },
            { icon: 'mdi-bank-circle', text: 'Statement Classification', title: 'Statement Classification', path: '/statement_classification/', display: false },
            { icon: 'mdi-bank-circle', text: 'Bank Statements', title: 'Bank Statements', path: '/bank_statements/', display: false },
            { divider: true, display: false, section: 'Management Accounts'},
            { icon: 'mdi-bank-circle', text: 'Bank Feed - Main Acc', title: 'Bank Feed - Main Acc', path: '/bank_feed_main_acc/', display: false },
            { icon: 'mdi-bank-circle', text: 'Bank Feed - Corp Bond Acc', title: 'Bank Feed - Corp Bond Acc', path: '/bank_feed_corp_bond_acc/', display: false },
            { icon: 'mdi-bank-circle', text: 'Bank Feed - Reserves Acc', title: 'Bank Feed - Reserves Acc', path: '/bank_feed_reserves_acc/', display: false },
            { icon: 'mdi-bank-circle', text: 'Softy-Comp Feed', title: 'Softy-Comp Feed', path: '/softy_comp_feed/', display: false },
            { divider: true, display: false, section: 'Management Accounts'},

            // { icon: 'mdi-cash-register', text: 'Premium Collections', title: 'Reports', path: '/premium_collections/', display: false },
            // { icon: 'mdi-cash-refund', text: 'Agent Settlements', title: 'Reports', path: '/agent_settlements/', display: false },
            // { icon: 'mdi-account-cash', text: 'Claim Settlements', title: 'Reports', path: '/claim_settlements/', display: false },
            // { icon: 'mdi-format-underline', text: 'Underwritter Payments', title: 'Reports', path: '/underwriter_payments/', display: false },
            // { icon: 'mdi-cash-check', text: 'FSP Licence Payments', title: 'Reports', path: '/reports/', display: false },

            { heading: 'Lookups', display: false },
            { icon: 'account_balance', text: 'Banks List', title: 'Banks List', path: '/banks-lookup/', display: false },
            { icon: 'mdi-chart-bar', text: 'Reports List', title: 'Reports List', path: '/reports-lookup/', display: false },
            { icon: 'mdi-human-male-male', text: 'Entity Relationship Types', title: 'Entity Relationship Types', path: '/reltype-lookup/', display: false },
            { icon: 'mdi-cash', text: 'Payout Age Categories', title: 'Payout Age Categories', path: '/payout-age-cat-lookup/', display: false },
            { icon: 'mdi-checkbox-multiple-marked', text: 'Product Rule Parameters', title: 'Product Rule Parameters', path: '/product_rule_parameters/', display: false },
            { icon: 'mdi-clipboard-plus', text: 'VAS Services', title: 'VAS Services', path: '/vas/', display: false },
            { icon: 'mdi-chart-areaspline', text: 'Dashboards', title: 'Dashboards', path: '/dashboards/', display: false },
            { icon: 'mdi-cash', text: 'Business Process List', title: 'Business Process List', path: '/business_process_list/', display: false },
            { divider: true, display: false, section: 'Lookups' },

            { heading: 'Settings', display: false },
            { icon: 'supervisor_account', text: 'User Accounts', title: 'User Accounts', path: '/users/', display: false },
            { icon: 'mdi-crown', text: 'User Roles', title: 'User Roles', path: '/user_roles/', display: false },
            { icon: 'mdi-fullscreen', text: 'User Screens', title: 'User Screens', path: '/user_screens/', display: false },
            { icon: 'mdi-api', text: 'API Integrations ', title: 'API Integrations', path: '/api_integrations/', display: false },
            // { icon: 'settings', text: 'Settings', title: 'Settings', path: '/settings/' },
            // { icon: 'chat_bubble', text: 'Trash' },
            // { icon: 'help', text: 'Help' },
            // { icon: 'phonelink', text: 'App downloads' },
            // { icon: 'keyboard', text: 'Keyboard shortcuts' },
            // { icon: 'settings', text: 'Settings' },
            // { icon: 'chat_bubble', text: 'Trash' },
            // { icon: 'help', text: 'Help' },
            // { icon: 'phonelink', text: 'App downloads' },
            // { icon: 'keyboard', text: 'Keyboard shortcuts' },
        ],

        refreshing_context_lookup_list: false
    }),

    methods: {
        openBusinessProcessTriggerDialog() {
            this.open_business_process_trigger_dialog = true;
        },
        openDrawer() {
            this.drawer = !this.drawer;
        },
        navigate(tab) {      
            if (this.$route.path !== tab.path) {
                const matchingTabs = this.tabItems.filter(tabItem => {
                    return tabItem.path === tab.path;
                });

                if (matchingTabs.length === 0) {
                    this.tabItems.push(tab);
                }

                this.$router.push(tab.path);
            }

            // Save to state
            store.commit('update_tabs', this.tabItems);

            // Close nav bar
            this.drawer = !this.drawer;
        },
        userLogout() {
            // Socket IO join context room
            const user_context = store.state.currentUserAccount.business_partner._id;
            this.$socket.client.emit('leave_room', user_context);

            // Call the mixin function
            this.logout();
        },
        async deepRefresh() {
            // reload user data (mainly roles and screens assigned)

            this.deep_refreshing = true;

            // const serverRes = await userDeepRefresh();

            // deepRefresh
            const serverRes = await this.$apollo
                                        .mutate({
                                            mutation: authDeepRefresh,
                                            // variables: {
                                            //     context_id: new_context
                                            // },
                                        });
            if (serverRes.data.authDeepRefresh.errors === true) {
                emitAlert(serverRes.data.authDeepRefresh.message);
                this.submitting = false;
                return;
            }

            // store.commit('update_token', serverRes.token);

            // Temporarilly set context list - This is needed for when retrieving the logged-in-user
            store.commit('update_context_list', [{
                selected: true,
                business_partner: this.selected_context
            }]);
            
            // Get user-account info
            const userAccount = serverRes.data.authDeepRefresh.user;
            
            store.commit('update_user', userAccount);
            // Set ctx list
            let ctx_list = []
            if (serverRes.data.authDeepRefresh.context_list.length > 0) {
                ctx_list = serverRes.data.authDeepRefresh.context_list;
            }
            store.commit('update_context_list', ctx_list);

            // Set report download map
            const user_premitted_reports = userAccount.roles[0].role.allowed_reports.map(allowed_report => {
                return allowed_report.report;
            });
            const REPORT_DOWNLOAD_PROGRESS_MAP = {};

            user_premitted_reports.forEach(report => {
                REPORT_DOWNLOAD_PROGRESS_MAP[ report.report_number ] = {
                    downloading: false,
                    download_progress: 0,
                    downloaded_file: null
                };
            });

            store.commit('update_report_download_progress_map', REPORT_DOWNLOAD_PROGRESS_MAP);


            // Set process progress map
            const user_premitted_business_processes = userAccount.roles[0].role.allowed_business_processes.map(allowed_business_process => {
                return allowed_business_process.process;
            });
            const BUSINESS_PROCESS_PROGRESS_MAP = {};

            user_premitted_business_processes.forEach(process => {
                BUSINESS_PROCESS_PROGRESS_MAP[ process.process_number ] = {
                    downloading: false,
                    download_progress: 0,
                    downloaded_file: null
                };
            });

            store.commit('update_business_process_progress_map', BUSINESS_PROCESS_PROGRESS_MAP);

            // Reset Tab Items
            const tabItems = [store.state.tabItems[0]];
            store.commit('update_tabs', tabItems);

            // if (serverRes.errors === true) {
            //     emitAlert(serverRes.message);
            // } else {
            //     if (serverRes.errors === true) {
            //         emitAlert(serverRes.message);
            //     } else {
                    
            //     }
            // }

            // this.deep_refreshing = false;
            // Refresh screen so that changes can propagate
            window.location.replace('/');
        },
        setMenuItemsVisibility(screens_list, section_header_name) {
            this.items.forEach(menu_item => {
                // Menu Item
                if (screens_list.includes(menu_item.title)) {
                    menu_item.display = true;
                } 
                
                // Section Header
                if (menu_item.heading === section_header_name) {
                    menu_item.display = true;
                }

                // Divider
                if (menu_item.section === section_header_name) {
                    menu_item.display = true;
                }
            });
        },

        async contextChange(new_context) {
            // console.log('new_context', new_context);
            // const selected_context = store.state.context_list.filter(ctx => { return ctx.selected === true })[0];
            // const context = selected_context.business_partner._id;

            // if (new_context === context) {
            //     console.log('Context didnt change');
            //     // return;
            // }
            
            this.switching_context = true;

            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: authSwitchContext,
                                                variables: {
                                                    context_id: new_context
                                                },
                                            });
                if (res.data.authSwitchContext.errors === true) {
                    emitAlert(res.data.authSwitchContext.message);
                    this.submitting = false;
                    return;
                }

                const ctx_list = res.data.authSwitchContext.context_list;
                // const ctx_list = res.context_list;
                store.commit('update_context_list', ctx_list);

                // Reset user menus
                this.setUserMenus();

                // this.switching_context = false;
                const tabItems = [store.state.tabItems[0]];
                store.commit('update_tabs', tabItems);

                // Reload system
                window.location.replace('/');
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.authSwitchContext.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }


            // setTimeout(()=> {
            //     // console.log('Aha')
            //     this.switching_context = false;
            // }, 3000)
        },

        async refreshContextLookupList() {
            // Get current selected context
            const selected_context = store.state.context_list.filter(ctx => { return ctx.selected === true })[0];
            const context = selected_context.business_partner._id;

            this.refreshing_context_lookup_list = true;

            try {
                // const res = await switchUserContext(context);
                const res = await this.$apollo
                                            .mutate({
                                                mutation: authSwitchContext,
                                                variables: {
                                                    context_id: context
                                                },
                                            });
                if (res.data.authSwitchContext.errors === true) {
                    emitAlert(res.data.authSwitchContext.message);
                    this.refreshing_context_lookup_list = false;
                    return;
                }

                // if (res.errors === true) {
                //     emitAlert(res.message);
                //     return;
                // }

                // const ctx_list = res.context_list;
                const ctx_list = res.data.authSwitchContext.context_list;
                store.commit('update_context_list', ctx_list);

                // Reset user menus
                this.setUserMenus();

                this.refreshing_context_lookup_list = false;
            } catch (error) {
                this.refreshing_context_lookup_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        setUserMenus() {
            // --------------------------------------------
            // --------------- SET CONTEXT ----------------
            // --------------------------------------------
            if (store.state.context_list.length > 0) {
                this.selected_context = store.state.context_list.filter(ctx => { return ctx.selected })[0].business_partner._id;
                
                // if (store.state.currentUserAccount.level === config.USER_LEVELS.ROOT_ADMIN) {
                //     this.display_context_selector = true;
                // }
                
                const isCentralUnderwriterAdminUser = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(config.BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'admin - central underwriter'
                                                            );
                                                        }).length > 0;

                if (isCentralUnderwriterAdminUser) {
                    this.display_context_selector = true;
                }
            }

            //-----------------------------------------------------------------------------------------//
            //--------------------------SET MENU ITEM VISIBILITY BASED ON USER ROLE--------------------//
            //-----------------------------------------------------------------------------------------//
            if (this.currentUserAccount !== null) {
                const screensMap = {};
                for (const roleObj of this.currentUserAccount.roles) {
                    for (const screenObj of roleObj.role.allowed_screens) {
                        // The following is for de-duplicating screens across the roles
                        screensMap[ screenObj._id ] = screenObj.screen;
                    }
                }
                this.user_permitted_screens = Object.values(screensMap);

                // this.user_permitted_screens = this.currentUserAccount.roles[0].role.allowed_screens.map(allowed_screen => {
                //     return allowed_screen.screen;
                // });

                // Categorize Dashboard Screens
                const dashboard_screens = this.user_permitted_screens.filter(screen => {
                    if (screen.category === 'Dashboard') {
                        return true
                    }
                }).map(screen => { return screen.name });
                this.setMenuItemsVisibility(dashboard_screens, 'Dashboard');

                // Credit Screens
                const credit_screens = this.user_permitted_screens.filter(screen => {
                    if (screen.category === 'Credit') {
                        return true
                    }
                }).map(screen => { return screen.name });
                if (credit_screens.length > 0) {
                    this.setMenuItemsVisibility(credit_screens, 'Credit');
                }

                // Categorize Central-Underwriting Screens
                const central_underwriting_screens = this.user_permitted_screens.filter(screen => {
                    if (screen.category === 'Central Underwriting') {
                        return true
                    }
                }).map(screen => { return screen.name });
                if (central_underwriting_screens.length > 0) {
                    this.setMenuItemsVisibility(central_underwriting_screens, 'Central Underwriting');
                }

                // Categorize Core-Insurance-System Screens
                const core_insurance_system_screens = this.user_permitted_screens.filter(screen => {
                    if (screen.category === 'Core Insurance System') {
                        return true
                    }
                }).map(screen => { return screen.name });
                if (core_insurance_system_screens.length > 0) {
                    this.setMenuItemsVisibility(core_insurance_system_screens, 'Core Insurance System');
                }

                // Categorize Finance Screens
                const finance_screens = this.user_permitted_screens.filter(screen => {
                    if (screen.category === 'Finance Management') {
                        return true
                    }
                }).map(screen => { return screen.name });
                if (finance_screens.length > 0) {
                    this.setMenuItemsVisibility(finance_screens, 'Finance Management');
                }

                // Categorize Lookup Screens
                const lookup_screens = this.user_permitted_screens.filter(screen => {
                    if (screen.category === 'Lookups') {
                        return true
                    }
                }).map(screen => { return screen.name });
                if (lookup_screens.length > 0) {
                    this.setMenuItemsVisibility(lookup_screens, 'Lookups');
                }

                // Categorize Settings Screens
                const settings_screens = this.user_permitted_screens.filter(screen => {
                    if (screen.category === 'Settings') {
                        return true
                    }
                }).map(screen => { return screen.name });
                if (settings_screens.length > 0) {
                    this.setMenuItemsVisibility(settings_screens, 'Settings');
                }

                // Categorize Management Accounts Screens
                const management_accounts_screens = this.user_permitted_screens.filter(screen => {
                    if (screen.category === 'Management Accounts') {
                        return true
                    }
                }).map(screen => { return screen.name });
                if (management_accounts_screens.length > 0) {
                    this.setMenuItemsVisibility(management_accounts_screens, 'Management Accounts');
                }
            }
        },

        closeBusinessProcessTriggerDialog() {
            this.open_business_process_trigger_dialog = false;
        }
    },

    computed: {
        hide() {
            return this.$route.path === '/auth/'; 
        },
    },

    async mounted() {
        eventBus.$on('progressBar', (toggle) => {
            this.progressBar = toggle;
        });

        this.setUserMenus();

        eventBus.$on('CLOSE_TRIGGER_BUSINESS_PROCESS_DIALOG', this.closeBusinessProcessTriggerDialog);
    },
    watch: {
            
    }
};
</script>

<style scoped>
</style>