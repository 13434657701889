<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Upload Bank Feed - For MAIN Account</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddBankDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" :sm="bank_account_name === null ? '12' : '6' ">
                            <v-select
                                v-model="bank_account_name"
                                :items="['MAIN']"
                                label="Bank Account"
                                color="secondary"
                                disabled
                                required
                            ></v-select>
                            <!-- <v-select
                                v-model="bank_account_name"
                                :items="['MAIN', 'CORPORATE-BONDS', 'SOFTY-COMP']"
                                label="Bank Account"
                                color="secondary"
                                required
                            ></v-select> -->
                        </v-col>
                        <!-- Statement CSV File -->
                        <v-col cols="12" sm="6" v-if="bank_account_name !== null">
                            <v-file-input
                                v-model="file.file"
                                color="secondary"
                                accept="text/csv"
                                label="File/Document"
                            ></v-file-input>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Bank Feed...
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitBankBtnDisabled()"
                                @click="submitBank()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Bank Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../utils/api";
import { eventBus } from "../../../../main";
import { captureBankFeed } from "../gql/mutations";
export default {
    name: 'CaptureBankFeedDialog',
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        server_resp_bank: null,
        transactions_list: [],
        submitting: false,

        bank_account_name: 'MAIN',
        file: {
            file: null
        },
    }),
    methods: {
        closeAddBankDialog() {
            eventBus.$emit('CLOSE_CAPTURE_BANK_FEED_DIALOG', this.server_resp_bank);
        },
        resetForm() {
            this.server_resp_bank = null;
            this.bank_account_name = 'MAIN',
            this.file.file = null;
            this.transactions_list = [];
        },
        submitBankBtnDisabled() {
            if (this.bank_account_name === null) {
                return true;
            }
            if (this.file.file === null) {
                return true;
            }

            if (this.transactions_list.length === 0) {
                return true;
            }

            return false;
        },
        async submitBank() {
            this.submitting = true;

            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: captureBankFeed,
                                                variables: {
                                                    input: {
                                                        OP_TYPE: 'PROCESS_BANK_FEED',
                                                        bank_feed: this.transactions_list,
                                                    }
                                                },
                                            });
                if (res.data.businessProcessManagementAccounts.errors === true) {
                    emitAlert(res.data.businessProcessManagementAccounts.message);
                    this.submitting = false;
                    return;
                }

                emitSuccess(res.data.businessProcessManagementAccounts.message);
                
                this.submitting = false;
                this.server_resp_bank = res.data.businessProcessManagementAccounts.business_objects;
                this.closeAddBankDialog();

                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessManagementAccounts.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        isValidDate(d) {
            return d instanceof Date && !isNaN(d);
        }
    },
    mounted() {
        
    },
    watch: {
        'file.file': function(file) {
            console.log(file)

            if (file !== null) {
                const reader = new FileReader();
                reader.readAsText(file)

                reader.onload = () => {
                    const csvContent = reader.result;
                    // Perform operations on the contents of the CSV file
                    // console.log(csvContent);

                    let headerIndex = -1;
                    const resultTransactions = [];

                    const lines = csvContent.split('\n');
                    for (let line of lines) {
                        const lineIndex = lines.indexOf(line);
                        line = line.replace('\r', '');
                        const columns = line.split(',');
                        columns.forEach(column => { column.trim(); });

                        if (
                            columns[0] !== undefined &&
                            columns[1] !== undefined &&
                            columns[2] !== undefined &&
                            columns[3] !== undefined &&
                            columns[4] !== undefined &&
                            columns[5] !== undefined &&
                            columns[6] !== undefined
                        ) {
                            if (
                                columns[0].toLowerCase() === 'date' &&
                                columns[1].toLowerCase() === 'service fee' &&
                                columns[2].toLowerCase() === 'amount' &&
                                columns[3].toLowerCase() === 'description' &&
                                columns[4].toLowerCase() === 'reference' &&
                                columns[5].toLowerCase() === 'balance' &&
                                columns[6].toLowerCase() === 'cheque number'
                            ) {
                                headerIndex = lineIndex;
                                break;
                            }
                        }
                    }

                    if (headerIndex !== -1) {
                        for (let i = (headerIndex + 1); i < lines.length; i += 1) {
                            let line = lines[i];
                            line = line.replace('\r', '');

                            const columns = line.split(',');
                            // columns.forEach(column => {
                            //     // column.replace('"', '').replace('"', '');

                            //     column.replace(/['"]+/g, '');
                            //     column.trim();
                            // });

                            const date = new Date(columns[0]);
                            if (this.isValidDate(date)) {
                                // First remove quotes, and then remove additional white spaces
                                const description = columns[3].replace(/['"]+/g, '').replace(/\s+/g, ' ').trim();
                                const reference = columns[4].replace(/['"]+/g, '').replace(/\s+/g, ' ').trim();

                                const resultObj = {
                                    date: columns[0].replace(/\s+/g, ' ').trim(),
                                    bank_account_name: this.bank_account_name,
                                    // service_fee: columns[1].replace(/\s+/g, ' ').trim(),
                                    amount: parseFloat(columns[2].replace(/\s+/g, ' ').trim()),
                                    
                                    description: description,
                                    reference: reference,
                                    // balance: columns[5].replace(/\s+/g, ' ').trim(),
                                    // cheque_number: columns[6].replace(/\s+/g, ' ').trim(),
                                };
                                resultTransactions.push(resultObj);
                            }
                        }
                    }
                    this.transactions_list = resultTransactions;
                    console.log(this.transactions_list);
                };
            }
        }
    }
}
</script>
<style scoped>

</style>