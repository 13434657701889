import { render, staticRenderFns } from "./bank_feed_main_acc.vue?vue&type=template&id=23a26cd2&scoped=true&"
import script from "./bank_feed_main_acc.vue?vue&type=script&lang=js&"
export * from "./bank_feed_main_acc.vue?vue&type=script&lang=js&"
import style0 from "./bank_feed_main_acc.vue?vue&type=style&index=0&id=23a26cd2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23a26cd2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCardActions,VCol,VDataTable,VIcon,VRow,VToolbar,VTooltip})
