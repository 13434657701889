<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Update Classification</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddBankDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-autocomplete
                                v-model="selected_classification"
                                :items="classifications"
                                color="secondary"
                                label="Classification"
                                item-value="_id"
                                item-text="name"
                                return-object
                                :disabled="refreshing_classification_lookup_list"
                            >
                                <!-- Format prepend inner icon -->
                                <template v-slot:prepend-inner>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                v-on="on"
                                                color="success"
                                                @click="refreshStatementClassifications()"
                                            >
                                                mdi-refresh
                                            </v-icon>
                                        </template>
                                        <span>Refresh Classification List</span>
                                    </v-tooltip>
                                </template>
                            </v-autocomplete>


                            <!-- <v-select
                                v-model="selected_classification"
                                :items="classifications"
                                item-value="_id"
                                item-text="name"
                                label="Classification"
                                color="secondary"
                                return-object
                                :disabled="refreshing_classification_lookup_list"

                                hint="You can click the refresh icon next to the field if the classification list is incomplete"
                                persistent-hint

                                required
                            >
                                <template v-slot:prepend-inner>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                v-on="on"
                                                color="success"
                                                @click="refreshStatementClassifications()"
                                            >
                                                mdi-refresh
                                            </v-icon>
                                        </template>
                                        <span>Refresh Classification List</span>
                                    </v-tooltip>
                                </template>
                            </v-select> -->
                            <v-progress-linear
                                color="green"
                                class="mb-0"
                                height="4"
                                indeterminate
                                :active="refreshing_classification_lookup_list"
                            ></v-progress-linear>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Bank Feed...
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitBtnDisabled()"
                                @click="submitUpdateToServer()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Bank Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../utils/api";
import { eventBus } from "../../../../main";
import { captureBankFeed } from "../gql/mutations";
import { getClassifications } from "../gql/queries";
export default {
    name: 'CaptureBankFeedDialog',
    components: {
    },
    props: {
        id: {
            type: String,
            required: true,
            default: ''
        },
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        server_resp_bank: null,
        submitting: false,
        refreshing_classification_lookup_list: false,

        selected_classification: null,

        classifications: []
    }),
    methods: {
        closeAddBankDialog() {
            eventBus.$emit('CLOSE_UPDATE_BANK_FEED_ITEM_CLASSIFICATION_DIALOG_MAIN_ACC', this.server_resp_bank);
        },
        resetForm() {
            this.server_resp_bank = null;
            this.selected_classification = null;
        },
        submitBtnDisabled() {
            if (this.selected_classification === null) {
                return true;
            }

            return false;
        },
        async submitUpdateToServer() {
            this.submitting = true;

            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: captureBankFeed,
                                                variables: {
                                                    input: {
                                                        OP_TYPE: 'UPDATE_BANK_FEED_ITEM',
                                                        _id: this.id,
                                                        classification: this.selected_classification._id,
                                                    }
                                                },
                                            });
                if (res.data.businessProcessManagementAccounts.errors === true) {
                    emitAlert(res.data.businessProcessManagementAccounts.message);
                    this.submitting = false;
                    return;
                }

                emitSuccess(res.data.businessProcessManagementAccounts.message);
                
                this.submitting = false;
                this.server_resp_bank = res.data.businessProcessManagementAccounts.business_objects[0];
                this.closeAddBankDialog();

                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessManagementAccounts.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        isValidDate(d) {
            return d instanceof Date && !isNaN(d);
        },

        async refreshStatementClassifications() {
            try {
                this.classifications = [];
                this.refreshing_classification_lookup_list = true;
                const res = await this.$apollo
                                        .query({
                                            query: getClassifications,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'BANK_STATEMENT_CLASSIFICATION',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                }
                                            },
                                        });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.refreshing_classification_lookup_list = false;
                    return;
                }

                this.refreshing_classification_lookup_list = false;
                this.classifications = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.refreshing_classification_lookup_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        }
    },
    async mounted() {
        // Get classifications
        await this.refreshStatementClassifications();
    },
    watch: {}
}
</script>
<style scoped>

</style>